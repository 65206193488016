import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import {
    // useTranslate,
    useUpdate,
    useNotify,
    useRedirect,
} from 'react-admin';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton = ({ record }) => {
    // const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();

    const [approve, { loading }] = useUpdate('comments', record.id, { status: 1 }, record, {
        undoable: true,
        onSuccess: () => {
            notify('resources.comments.notification.approved_success', 'info', {}, true);
            redirectTo('/comments');
        },
        onFailure: () => {
            notify('resources.comments.notification.approved_error', 'warning');
        },
    });

    return record && record.status === 0 ? (
        <Button variant="outlined" color="primary" size="small" onClick={approve} disabled={loading}>
            <ThumbUp color="primary" style={{ paddingRight: '0.5em', color: 'green' }} />
            {/* {translate('resources.comments.action.accept')} */}
            Accept
        </Button>
    ) : (
        <span />
    );
};

AcceptButton.propTypes = {
    record: PropTypes.any,
};

export default AcceptButton;
