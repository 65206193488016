import React from 'react';
import { Create } from 'react-admin';
import Form from '../Form';

const UsersCreate = ({ permissions, ...props }) => {
    return (
        <Create {...props} undoable={false}>
            <Form {...props} />
        </Create>
    );
};

export default UsersCreate;
