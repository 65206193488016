import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { GMapInput } from '../fields/gmap';

const TownEdit = (props) => {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <TextInput source="name" label="common.name" />
                <GMapInput source="coordinates" />
            </SimpleForm>
        </Edit>
    );
};
export default TownEdit;
