import { Chip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0;
`;

const StyledChip = styled(Chip)`
    margin: 4px;
`;

function TagsField({ record }) {
    return record.tags.length > 0 ? (
        <Wrapper>
            {record.tags.map((tag) => (
                <StyledChip size="small" key={tag.id} label={tag.name} />
            ))}
        </Wrapper>
    ) : null;
}

export default TagsField;
