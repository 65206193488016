import greekMessages from './greekMessages';

const messages = {
    ...greekMessages,
    navigation: { skip_nav: 'Skip navigation' },
    common: {
        title: 'Τίτλος',
        order: 'Σειρά',
        status: 'Κατάσταση',
        metaDescription: 'Meta Description',
        metaKeywords: 'Meta Keywords',
        phone: 'Τηλέφωνο',
        name: 'Όνομα',
        coordinates: 'Συντεταγμένες',
        createdAt: 'Δημιουργήθηκε',
        startsAt: 'Ξεκινάει',
        endsAt: 'Τελειώνει',
        updatedAt: 'Ανανεώθηκε',
        expiresAt: 'Λήγει',
        summary: 'Περίληψη',
        general: 'Γενικά',
        image: 'Εικόνα',
        images: 'Εικόνες',
        mobileImage: 'Εικόνα για Κινητά',
        meta: 'Meta',
        isApproved: 'Εγκρίθηκε',
        description: 'Περιγραφή',
        active: 'Ενεργό',
        notActive: 'Ανενεργό',
        notes: 'Σημειώσεις',
        referrer: 'Αναφέρων',
        client: 'Πελάτης',
        map: 'Χάρτης',
        pending: 'Εκκρεμεί',
        reservations: 'Κρατήσεις',
        validate: 'Επαλήθευση',
    },
    dashboard: {
        pendingComments: 'Σχόλια σε εκκρεμότητα',
        pendingEvents: 'Εκδηλώσεις σε εκκρεμότητα',
        pendingListings: 'Προφίλ σε εκκρεμότητα',
        active: 'Ενεργό',
        notActive: 'Μη Ενεργό',
        expiringSubscriptions: 'Συνδρομές που Θα λήξουν',
    },
    upload: {
        generalError: 'Υπήρξε κάποιο πρόβλημα',
        fileTypeError: 'Επιτρέπονται μόνο εικόνες και pdf',
        uploadSuccess: 'Τα αρχεία ανέβηκαν με επιτυχία',
    },
    couponValidation: {
        notFound: 'Ο κωδικός δεν αντιστοιχεί σε κάποια κράτηση',
        validationSuccess: 'Η επικύρωση ήταν επιτυχής',
        search: 'Αναζήτηση',
    },
    resources: {
        areas: {
            name: 'Περιοχή |||| Περιοχές',
            fields: {
                name: 'Όνομα',
                cityId: 'Πόλη',
            },
        },
        articleCategories: {
            name: 'Κατηγορία Άρθρου |||| Κατηγορίες Άρθρου',
            fields: {},
        },
        articles: {
            name: 'Άρθρο |||| Άρθρα',
            fields: {
                authorId: 'Συγγραφέας',
                tags: 'Ετικέτες',
                content: 'Περιεχόμενο',
                authorName: 'Όνομα Συγγραφέα',
                authorDescription: 'Περιγραφή Συγγραφέα',
                categoryId: 'Κατηγορία',
                articleCategory: 'Κατηγορία Άρθρου',
                shortDescription: 'Σύντομη Περιγραφή',
            },
        },
        categories: {
            name: 'Κατηγορία |||| Κατηγορίες',
            fields: {
                description: 'Περιγραφή',
            },
        },
        listings: {
            name: 'Προφίλ |||| Προφίλ',
            fields: {
                shortDescription: 'Σύντομη Περιγραφή',
                phone: 'Τηλέφωνο',
                mobilePhone: 'Κινητό Τηλέφωνο',
                address: 'Διεύθυνση',
                hours: 'Ωράριο',
                logo: 'Λογότυπο',
                commission: 'Ποσοστό προμήθειας seller',
                clientServiceFee: 'Ποσοστό χρέωσης υπηρεσίας',
            },
        },
        coupons: {
            name: 'Κουπόνι |||| Κουπόνια',
            fields: {
                description: 'Περιγραφή',
                count: 'Σύνολο',
                listingId: 'Προφίλ',
                remainingCoupons: 'Υπολειπόμενα Κουπόνια',
                expirationDate: 'Ημερομηνία Λήξης',
                price: 'Τιμή',
                order: 'Σειρά',
            },
        },
        claimedcoupons: {
            name: 'Κατοχυρωμένο Κουπόνι |||| Κατοχυρωμένα Κουπόνια',
            fields: {
                hashCode: 'Κώδικας',
                validatedAt: 'Επαληθεύτηκε',
                couponId: 'Κουπόνι',
            },
            seat: 'Θέση',
        },
        events: {
            name: 'Εκδήλωση |||| Εκδηλώσεις',
            copyTickets: 'Αντιγραφή εισιτηρίων',
            copyTicketsSuccess: 'Η αντιγραφή των εισιτηρίων ήταν επιτυχής.',
            copyTicketsError: 'Υπήρξε πρόβλημα στην αντιγραφή των εισιτηρίων.',
            copyEventCategories: 'Αντιγραφή κατηγοριών',
            copyEventCategoriesSuccess: 'Η αντιγραφή των κατηγοριών ήταν επιτυχής.',
            copyEventCategoriesError: 'Υπήρξε πρόβλημα στην αντιγραφή των κατηγοριών.',
            fields: {
                url: 'Url',
                listingId: 'Προφίλ',
                categoryId: 'Κατηγορία',
                eventCategoryId: 'Κατηγορία Εκδηλώσεων',
                dates: 'Ημερομηνίες',
                areaId: 'Περιοχή',
                venue: 'Χώρος',
                recurringDates: 'Επαναλαμβανόμενες Ημερομηνίες',
                rrule: 'Επιλογές Επανάληψης',
                multipleDates: 'Πολλαπλές Ημερομηνίες',
                eventId: 'Πατρική Εκδήλωση',
                tickets: 'Εισιτήρια',
            },
        },
        eventcategories: {
            name: 'Κατηγορία Εκδήλωσης |||| Κατηγορίες Εκδήλωσης',
        },
        subscriptions: {
            name: 'Συνδρομή |||| Συνδρομές',
            fields: {
                paymentMethod: 'Μέθοδος πληρωμής',
                price: 'Τιμή',
            },
        },
        users: {
            name: 'Χρήστης |||| Χρήστες',
            fields: {
                role: 'Role',
                firstName: 'Όνομα',
                lastName: 'Επίθετο',
                email: 'Email',
                contactName: 'Όνομα Επαφής',
                mobilePhone: 'Κινητό Τηλέφωνο',
                password: 'Κωδικός Πρόσβασης',
                miscellaneous: 'Διάφορα',
                referrerId: 'Αναφέρων',
                address: 'Διεύθυνση',
                tin: 'ΑΦΜ',
                taxOffice: 'ΔΟΥ',
                commission: 'Ποσοστό προμήθειας seller',
                clientServiceFee: 'Ποσοστό χρέωσης υπηρεσίας',
            },
            kyc: {
                state: 'Κατάσταση επαλήθευσης Everypay',
                field: 'Πεδίο',
                fieldStatus: 'Κατάσταση',
                status: {
                    initial: 'Αρχική',
                    pending: 'Σε αναμονή',
                    verified: 'Επαληθεύτηκε',
                    rejected: 'Απορρίφθηκε',
                },
                processKYC: 'Επεξεργασία φόρμας KYC',
                company_info: 'Στοιχεία επιχείρησης',
                gov_kyc: 'Επιβεβαίωση στοιχείων εκπροσώπου',
                proof_of_id: 'Ταυτοποίηση εκπροσώπου',
                proof_of_funds: 'Αποδεικτικό εισοδήματος',
                proof_of_business_establishment: 'Αποδεικτικό επιχείρησης',
                proof_of_financials: 'Αποδεικτικό οικονομικών στοιχείων καταστάσεων',
                proof_of_residence: 'Aποδεικτικό κατοικίας',
                other_documents: 'Άλλα έγγραφα ',
                createSeller: 'Δημιουργία Everypay Seller',
                business_title: 'Τίτλος επιχείρησης',
                contact_phone: 'Τηλ. Επικοινωνίας',
                business_name: 'Επωνυμία Επιχείρησης',
                business_tax_number: 'ΑΦΜ',
                description: 'Περιγραφή',
                business_address: 'Διεύθυνση',
                bank_account_beneficiary: 'Δικαιούχος Τραπεζ. Λογαριασμού',
                bank_account_iban: 'Αρ. Λογαριασμού (IBAN)',
            },
        },
        comments: {
            name: 'Σχόλιο |||| Σχόλια',
            fields: {
                stars: 'Αστέρια',
                content: 'Περιεχόμενο',
            },
            detail: 'Λεπτομέριες',
        },
        cities: {
            name: 'Πόλη |||| Πόλεις',
        },
        plans: {
            name: 'Πλάνο |||| Πλάνα',
        },
        posters: {
            name: 'Μπάνερ |||| Μπάνερς',
            fields: {
                type: 'Τύπος',
            },
        },
        educationalarticles: {
            name: 'Εκπαιδευτικό Άρθρο |||| Εκπαιδευτικά Άρθρα',
            fields: {
                authorId: 'Συγγραφέας',
                tags: 'Ετικέτες',
                content: 'Περιεχόμενο',
                authorName: 'Όνομα Συγγραφέα',
                authorDescription: 'Περιγραφή Συγγραφέα',
                categoryId: 'Κατηγορία',
                educationalCategoryId: 'Εκπαιδευτική Κατηγορία',
                shortDescription: 'Σύντομη Περιγραφή',
            },
        },
        educationalcategories: {
            name: 'Κατηγορία |||| Κατηγορίες',
        },
        discountcodes: {
            name: 'Κωδικός Έκπτωσης |||| Κωδικοί Έκπτωσης',
            generate: 'Δημιουργία Κωδικών Έκπτωσης',
            generateSuccess: 'Η δημιουργία των κωδικών ήταν επιτυχής.',
            generateError: 'Υπήρξε πρόβλημα στην δημιουργία των κωδικών.',
            quantity: 'Ποσότητα',
            copyDiscountCodes: 'Αντιγραφή κωδικών έκπτωσης στις υπόλειπες εκδηλώσεις',
            copyDiscountCodesShort: 'Αντιγραφή στις υπόλειπες εκδηλώσεις',
            fields: {
                code: 'Κωδικός',
                discount: 'Ποσοστό Έκπτωσης',
                status: {
                    not_used: 'Μη Χρησιμοποιημένος',
                    used: 'Χρησιμοποιημένος',
                    pending: 'Σε εκκρεμότητα',
                },
            },
        },
        theaters: {
            name: 'Θέατρο |||| Θέατρα',
        },
        invoiceseries: {
            name: 'Σειρά Τιμολογίου |||| Σειρές Τιμολογίου',
            fields: {
                series: 'Σειρά',
                sequenceNumber: 'ΑΑ Τιμολογίου',
            }
        },
        receipts: {
            name: 'Απόδειξη |||| Αποδείξεις',
            fields: {
                series: 'Σειρά',
                sequenceNumber: 'ΑΑ Απόδειξης',
                name: 'Όνομα',
                email:  'Email',
                value: 'Ποσό',
                url: 'Σύνδεσμος',
            }
        }
    },
};

export default messages;
