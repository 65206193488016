import { Avatar } from '@material-ui/core';
import { ReferenceField } from 'ra-ui-materialui';
import styled from 'styled-components';
import config from '../../config';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
`;

const UserAvatar = styled(Avatar)`
    margin-top: -4px;
    margin-right: 8px;
    margin-bottom: -4px;
    width: 25px;
    height: 25px;
`;

const UserField = ({ record }) => {
    return (
        <Wrapper>
            <UserAvatar src={record.image ? `${config.apiUrl}/s3/uploads/${record.image}` : null} />
            {record.firstName} {record.lastName}
        </Wrapper>
    );
};

const UserReferenceField = ({ source, label }) => {
    return (
        <ReferenceField source={source} label={label} reference="users">
            <UserField />
        </ReferenceField>
    );
};

export default UserReferenceField;
