import List from './List';
// import Create from './create.react';
import Edit from './edit.react';

const resource = (ability) => ({
    list: ability.can('read', 'Coupon') ? List : null,
    // create: ability.can('create', 'Coupon') ? Create : null,
    edit: ability.can('update', 'Coupon') ? Edit : null,
});

export default resource;
