import englishMessages from 'ra-language-english';

const messages = {
    ...englishMessages,
    common: {
        title: 'Title',
        order: 'Order',
        status: 'Status',
        metaDescription: 'Meta Description',
        metaKeywords: 'Meta Keywords',
        phone: 'Phone',
        name: 'Name',
        coordinates: 'Coordinates',
        createdAt: 'Created At',
        startsAt: 'Starts At',
        endsAt: 'Ends At',
        updatedAt: 'Updated At',
        expiresAt: 'Expires At',
        summary: 'Summary',
        general: 'General',
        image: 'Image',
        images: 'Images',
        mobileImage: 'Mobile Image',
        meta: 'Meta',
        isApproved: 'Is Approved',
        description: 'Description',
        active: 'Active',
        notActive: 'Not Active',
        notes: 'Notes',
        referrer: 'Referrer',
        client: 'Client',
        map: 'Map',
        pending: 'Pending',
        reservations: 'Reservations',
        validate: 'Validate',
    },
    dashboard: {
        pendingComments: 'Pending Comments',
        pendingEvents: 'Pending Events',
        pendingListings: 'Pending Listings',
        expiringSubscriptions: 'Expiring Subscriptions',
        active: 'Active',
        notActive: 'Not Active',
    },
    upload: {
        generalError: 'There was a problem',
        fileTypeError: 'Only images and pdfs are allowed',
        uploadSuccess: 'The files where successfully uploaded',
    },
    couponValidation: {
        notFound: 'The code does not correspond to a reservation!',
        validationSuccess: 'Validation was successfull',
        search: 'Search',
    },
    resources: {
        areas: {
            name: 'Area |||| Areas',
            fields: {
                name: 'Name',
                cityId: 'City',
            },
        },
        articleCategories: {
            name: 'Article Category |||| Article Categories',
            fields: {},
        },
        articles: {
            name: 'Article |||| Articles',
            fields: {
                authorId: 'Author',
                tags: 'Tags',
                content: 'Content',
                authorName: 'Author Name',
                authorDescription: 'Author Description',
                categoryId: 'Category',
                articleCategory: 'Article Category',
                shortDescription: 'Short Description',
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                description: 'Description',
            },
        },
        coupons: {
            name: 'Coupon |||| Coupons',
            fields: {
                description: 'Description',
                count: 'Count',
                listingId: 'Listing',
                remainingCoupons: 'Remaining Coupons',
                expirationDate: 'Expiration Date',
                price: 'Price',
                order: 'Order',
            },
        },
        claimedcoupons: {
            name: 'Claimed Coupon |||| Claimed Coupons',
            fields: {
                hashCode: 'Code',
                validatedAt: 'Validated At',
                couponId: 'Coupon',
            },
            seat: 'Seat',
        },
        events: {
            name: 'Events |||| Events',
            copyTickets: 'Copy tickets',
            copyTicketsSuccess: 'Tickets copied successfully.',
            copyTicketsError: 'There was an error while coping tickets.',
            copyEventCategories: 'Copy categories',
            copyEventCategoriesSuccess: 'Categories copied successfully.',
            copyEventCategoriesError: 'There was an error while coping categories.',
            fields: {
                url: 'Url',
                listingId: 'Listing',
                categoryId: 'Category',
                eventCategoryId: 'Event Category',
                dates: 'Dates',
                recurringDates: 'Recurring Dates',
                rrule: 'Recurence Options',
                multipleDates: 'Multiple Dates',
                eventId: 'Parent Event',
                venue: 'Venue',
                tickets: 'Tickets',
            },
        },
        eventcategories: {
            name: 'Event Category |||| Event Categories',
        },
        listings: {
            name: 'Listing |||| Listings',
            fields: {
                shortDescription: 'Short Description',
                phone: 'Phone',
                mobilePhone: 'Mobile Phone',
                address: 'Address',
                hours: 'Hours',
                logo: 'Logo',
                commission: 'Seller commission percentage',
                clientServiceFee: 'Service fee percentage',
            },
        },
        subscriptions: {
            name: 'Subscription |||| Subscriptions',
            fields: {
                paymentMethod: 'Payment Method',
                price: 'Price',
            },
        },
        users: {
            name: 'User |||| Users',
            fields: {
                role: 'Role',
                firstName: 'First Name',
                lastName: 'Last Name',
                email: 'Email',
                contactName: 'Contact Name',
                mobilePhone: 'Mobile Phone',
                password: 'Password',
                miscellaneous: 'Miscellaneous',
                referrerId: 'Referrer',
                address: 'Address',
                tin: 'TIN',
                taxOffice: 'Tax Office',
                commission: 'Seller commission percentage',
                clientServiceFee: 'Service fee percentage',
            },
            kyc: {
                state: 'Everypay KYC status',
                field: 'Field',
                fieldStatus: 'Status',
                status: {
                    initial: 'Initial',
                    pending: 'Pending',
                    verified: 'Verified',
                    rejected: 'Rejected',
                },
                processKYC: 'Process KYC form',
                company_info: 'Company info',
                gov_kyc: 'Gov kyc',
                proof_of_id: 'Proof of id',
                proof_of_funds: 'Proof of funds',
                proof_of_business_establishment: 'Proof of business establishment',
                proof_of_financials: 'Proof of financials',
                proof_of_residence: 'Proof of residence',
                other_documents: 'Other documents',
                createSeller: 'Create Everypay Seller',
                business_title: 'Business Title',
                contact_phone: 'Contact phone',
                business_name: 'Business name',
                business_tax_number: 'Business tax number',
                description: 'Description',
                business_address: 'Business address',
                bank_account_beneficiary: 'Bank account beneficiary',
                bank_account_iban: 'IBAN',
            },
        },
        comments: {
            name: 'Comment |||| Comments',
            fields: {
                stars: 'Stars',
                content: 'Content',
            },
            detail: 'Comment Detail',
        },
        cities: {
            name: 'City |||| Cities',
        },
        plans: {
            name: 'Plan |||| Plans',
        },
        posters: {
            name: 'Poster |||| Posters',
            fields: {
                type: 'Type',
            },
        },
        educationalarticles: {
            name: 'Educational Article |||| Educational Articles',
            fields: {
                authorId: 'Author',
                tags: 'Tags',
                content: 'Content',
                authorName: 'Author Name',
                authorDescription: 'Author Description',
                categoryId: 'Category',
                educationalCategory: 'Educational Category',
                shortDescription: 'Short Description',
            },
        },
        educationalcategories: {
            name: 'Educational Category |||| Educational Categories',
        },
        discountcodes: {
            name: 'DiscountCode |||| DiscountCodes',
            generate: 'Generate Discount Codes',
            quantity: 'Quantity',
            generateSuccess: 'Discount codes generated successfully.',
            generateError: 'There was an error while generating discount codes.',
            copyDiscountCodes: 'Copy discount codes to remaining events',
            copyDiscountCodesShort: 'Copy to remaining events',
            fields: {
                code: 'Code',
                discount: 'Discount Percentage',
                status: {
                    not_used: 'Not Used',
                    used: 'Used',
                    pending: 'Pending',
                },
            },
        },
        theaters: {
            name: 'Theater |||| Theaters',
        },
        invoiceseries: {
            name: 'Invoice Series |||| Invoice Series',
            fields: {
                series: 'Series',
                sequenceNumber: 'Sequence Number',
            },
        },
        receipts: {
            name: 'Receipt |||| Receipts',
            fields: {
                series: 'Series',
                sequenceNumber: 'Sequence Number',
                name: 'Name',
                email: 'Email',
                value: 'Value',
                url: 'URL',
            },
        },
    },
};

export default messages;
