import { Timeline } from '@kidsproject/ra-audit-log';
import { useGetList } from 'react-admin';

const AuditLog = () => {
    const { data, ids, loaded } = useGetList(
        'auditlogevents',
        { page: 1, perPage: 25 },
        { field: 'date', order: 'desc' }
    );
    const records = ids.map((id) => data[id]);

    return <Timeline loaded={loaded} records={records} />;
};

export default AuditLog;
