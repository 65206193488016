import { withWidth } from '@material-ui/core';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';
import { ReactSortable } from 'react-sortablejs';
import File from './file.react';

const FilesList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    & > div {
        display: grid;
        grid-template-columns: repeat(${(props) => props.cols}, 1fr);
        grid-gap: 2px;
    }
`;

const getCols = (width) => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 3;
    if (width === 'lg') return 5;
    return 6;
};

function FileList({ apiRoot, fileCoverImg, files, setFiles, deleteFile, width, multiple, ...rest }) {
    const [showGallery, setShowGallery] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const showBiggerImg = (index) => {
        setShowGallery(!showGallery);
        setActiveIndex(index);
    };

    return files.length > 0 ? (
        <>
            <FilesList cellHeight={180} cols={getCols(width)}>
                <ReactSortable
                    list={files.map((file, index) => ({ id: index + 1, name: file }))}
                    setList={(list) => {
                        setFiles(list.map((item) => item.name));
                    }}
                >
                    {files.map((file, i) => (
                        <File
                            key={i}
                            index={i}
                            apiRoot={apiRoot}
                            file={file}
                            fileCoverImg={fileCoverImg}
                            showBiggerImg={showBiggerImg}
                            deleteFile={deleteFile}
                        />
                    ))}
                </ReactSortable>
            </FilesList>
            {showGallery && (
                <Lightbox
                    mainSrc={`${apiRoot}/s3/uploads/${files[activeIndex]}`}
                    onMovePrevRequest={() =>
                        setActiveIndex((activeIndex) => (activeIndex + files.length - 1) % files.length)
                    }
                    onMoveNextRequest={() => setActiveIndex((activeIndex) => (activeIndex + 1) % files.length)}
                    onCloseRequest={() => setShowGallery(false)}
                />
            )}
        </>
    ) : (
        <div>No Files</div>
    );
}

export default withWidth()(FileList);
