import axios from 'axios';
import React, { useState, useCallback } from 'react';
import {
    required,
    TextInput,
    Button,
    ReferenceManyField,
    TextField,
    NumberInput,
    useNotify,
    useTranslate,
    usePermissions,
} from 'react-admin';
import { EditableDatagrid, RowForm } from '@kidsproject/ra-editable-datagrid';
import IconContentAdd from '@material-ui/icons/Add';
import QuickCreate from '../common/QuickCreate';
import config from '../config';
import CreateDiscountCodesDialog from './CreateDiscountCodesDialog';
import DiscountCodes from './DiscountCodes';

const CouponForm = (props) => {
    return (
        <RowForm {...props}>
            <TextInput source="title" />
            <TextInput source="description" />
            <NumberInput source="seatsPerCoupon" />
            <NumberInput source="price" />
        </RowForm>
    );
};

const CouponReferenceInput = (props) => {
    const { permissions: { role } = { role: 'editor' } } = usePermissions();
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => {
        setVersion(version + 1);
    }, [version]);
    const notify = useNotify();
    const t = useTranslate();
    const [showDialog, setShowDialog] = useState(false);

    return (
        <div>
            <QuickCreate onChange={handleChange} resource="coupons" initialValues={{ eventId: props.record.id }}>
                <TextInput source="title" validate={required()} fullWidth />
                <TextInput source="description" validate={required()} fullWidth multiline />
                <NumberInput source="seatsPerCoupon" fullWidth />
                <NumberInput source="price" fullWidth />
            </QuickCreate>

            {role !== 'editor' && (
                <Button label={t('resources.discountcodes.generate')} onClick={() => setShowDialog(true)} />
            )}

            {role !== 'editor' && (
                <CreateDiscountCodesDialog
                    coupons={props.record.coupons}
                    open={showDialog}
                    onClose={() => setShowDialog(false)}
                />
            )}

            {!props.record.eventId && (
                <Button
                    label="resources.events.copyTickets"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        if (window.confirm('Είστε σίγουροι;')) {
                            axios
                                .post(`${config.apiUrl}/event-coupons/copy`, { eventId: props.record.id })
                                .then((res) => {
                                    notify('resources.events.copyTicketsSuccess', 'success');
                                })
                                .catch((err) => {
                                    notify('resources.events.copyTicketsError', 'warning');
                                });
                        }
                    }}
                >
                    <IconContentAdd />
                </Button>
            )}

            <div>
                <ReferenceManyField reference="coupons" target="events.id" addLabel={false} fullWidth key={version}>
                    <EditableDatagrid editForm={<CouponForm />} expand={<DiscountCodes />}>
                        <TextField source="title" />
                        <TextField source="description" />
                        <TextField source="seatsPerCoupon" />
                        <TextField source="price" />
                    </EditableDatagrid>
                </ReferenceManyField>
            </div>
        </div>
    );
};

export default CouponReferenceInput;
