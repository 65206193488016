import React from 'react';
import Form from './Form';
import { Edit } from 'react-admin';

function EditSubscription(props) {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <Form {...props} />
        </Edit>
    );
}

export default EditSubscription;
