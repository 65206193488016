import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useInput } from 'ra-core';
import { Button, makeStyles } from '@material-ui/core';
import { CloudUpload, PhotoLibrary } from '@material-ui/icons';
import _isEqual from 'lodash/isEqual';
// import ReactS3Uploader from 'react-s3-uploader';
import FileList from './fileList/fileList.react';
import Loading from './loading.react';
import MediaLibraryModal from './MediaLibraryModal';
import config from '../../config';
import { getFiles } from './utils';
// import AuthManager from '../../authManager';
import { Labeled } from 'react-admin';

const UploadButton = styled(Button)`
    align-self: flex-start;
    margin-top: 20px;
`;

const MediaLibrary = styled(Button)`
    align-self: flex-start;
    margin-top: 20px;
`;

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
});

function FileUploader({
    record,
    multiple,
    source,
    disabled,
    uploadOptions,
    apiRoot,
    fileCoverImg = 'https://via.placeholder.com/150',
    resource,
    validate,
    limit,
    label,
    ...rest
}) {
    const { input, meta, isRequired } = useInput({ source, resource, validate });
    const [fileList, setFileList] = useState(getFiles(input, record, source));
    const [loading, setLoading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const uploadInput = useRef();
    const prevFileList = useRef(fileList);
    const classes = useStyles();

    useEffect(() => {
        if (multiple) {
            if (!_isEqual(prevFileList.current, fileList)) {
                input.onChange(fileList);
            }
        } else {
            if (fileList.length > 0) {
                input.onChange(fileList[0]);
            }
        }

        prevFileList.current = fileList;
    }, [multiple, fileList, input]);

    const deleteFile = (fileName) => {
        if (multiple) {
            setFileList((fileList) => {
                const newFileList = fileList.filter((file) => file !== fileName);
                return newFileList;
            });
        } else {
            setFileList([]);
            input.onChange('');
        }
    };

    const onFinishFileUpload = (result) => {
        setLoading(false);
        if (multiple) {
            setFileList((list) => {
                const newFileList = [...list, ...result];
                return newFileList;
            });
        } else {
            input.onChange(result[0]);
            setFileList(result);
        }
    };

    const onFileProgress = (percentage) => {
        if (percentage === 0) {
            setLoading(true);
            return;
        }
        if (percentage === 100) {
            setLoading(false);
            return;
        }
        setPercent(percentage);
    };

    const onUploadClick = () => {
        uploadInput.current.click();
    };

    const handleFileUpload = (event) => {
        setLoading(true);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${config.apiUrl}/upload`);

        const formData = new FormData();
        formData.append('path', record && record.id ? `${resource}/${record.id}` : resource);
        Object.keys(event.target.files).forEach((key) => {
            formData.append('resources', event.target.files[key]);
        });

        axios
            .post(`${config.apiUrl}/upload`, formData, {
                onUploadProgress: (progressEvent) => {
                    onFileProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                },
                withCredentials: false,
            })
            .then((res) => {
                const files = res.data.files.map((x) => `${x.key}`);
                onFinishFileUpload(files);
            });
    };

    return (
        <Labeled label={label || source} isRequired={isRequired} meta={meta} className={classes.root}>
            <React.Fragment>
                <MediaLibraryModal
                    open={isModalOpen}
                    handleClose={() => setIsModalOpen(false)}
                    files={fileList}
                    setFiles={setFileList}
                    multiple={multiple}
                />
                <FileList
                    apiRoot={apiRoot}
                    fileCoverImg={fileCoverImg}
                    files={fileList}
                    setFiles={setFileList}
                    deleteFile={deleteFile}
                    disabled={disabled}
                    multiple={multiple}
                    {...rest}
                />
                {loading && <Loading percentage={percent} />}
                <React.Fragment>
                    <UploadButton
                        variant="contained"
                        color="default"
                        startIcon={<CloudUpload />}
                        onClick={onUploadClick}
                    >
                        Upload
                    </UploadButton>
                    <MediaLibrary
                        variant="contained"
                        color="default"
                        startIcon={<PhotoLibrary />}
                        onClick={() => setIsModalOpen(true)}
                    >
                        Media Library
                    </MediaLibrary>
                    <input
                        ref={uploadInput}
                        onChange={handleFileUpload}
                        type="file"
                        style={{ display: 'none' }}
                        multiple={multiple}
                    />
                </React.Fragment>
            </React.Fragment>
        </Labeled>
    );
}

export default FileUploader;
