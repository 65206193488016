import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { rrulestr } from 'rrule';
import ReactRRuleGenerator from '@kidsproject/rrule-generator-react';
import { useInput } from 'ra-core';
import { Labeled } from 'ra-ui-materialui';
import { Close } from '@material-ui/icons';
import { InputLabel, FormControl, Input, InputAdornment, IconButton } from '@material-ui/core';
import Modal from '../../common/modal';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

function RRuleIPnput({ record, source, resource, validate, label, startDate }) {
    const { input, isRequired, meta } = useInput({ source, resource, validate });
    const [open, setOpen] = useState(false);

    const rRuleText = useMemo(() => {
        if (input.value) {
            const rRule = rrulestr(input.value);
            return rRule.toText();
        }

        return '';
    }, [input.value]);

    return (
        <Labeled label={label ? label : source} isRequired={isRequired} meta={meta}>
            <>
                <Modal
                    open={open}
                    title="Recurence Options"
                    onClose={() => setOpen(false)}
                    onSave={() => {
                        setOpen(false);
                    }}
                >
                    <div>
                        <ReactRRuleGenerator
                            config={{
                                hideStart: true,
                            }}
                            onChange={(rRule) => {
                                input.onChange(rRule);
                            }}
                            value={input.value}
                        />
                    </div>
                </Modal>

                <Wrapper>
                    <FormControl>
                        {rRuleText && <InputLabel htmlFor="rrule">{rRuleText}</InputLabel>}
                        <Input
                            placeholder="Select..."
                            type="text"
                            value={input.value ? input.value : ''}
                            onClick={() => setOpen(true)}
                            endAdornment={
                                input.value ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                input.onChange('');
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null
                            }
                        />
                    </FormControl>
                </Wrapper>
            </>
        </Labeled>
    );
}

export default RRuleIPnput;
