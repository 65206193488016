import List from './list.react';
import Create from './Create';
import Edit from './Edit';

const resource = (ability) => ({
    list: ability.can('read', 'Listing') ? List : null,
    create: ability.can('create', 'Listing') ? Create : null,
    edit: ability.can('update', 'Listing') ? Edit : null,
});

export default resource;
