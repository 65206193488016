import React from 'react';
import { Create } from 'react-admin';
import Form from './Form';

const AreaCreate = (props) => (
    <Create {...props}>
        <Form {...props} />
    </Create>
);

export default AreaCreate;
