import React, { useState } from 'react';
import { useInput } from 'ra-core';
import { Labeled } from 'react-admin';
import Datetime from 'react-datetime';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Event, Clear } from '@material-ui/icons';

const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;

const Input = styled.input`
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    padding: 11px;
    border: ${(props) => (props.error ? '1px solid red' : null)};
    user-select: text;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    right: -20px;
    display: flex;
`;

const CustomInput = ({ date, meta, onChange, ...props }) => {
    return (
        <Wrapper>
            <Input
                {...props}
                readOnly
                value={date ? props.value : ''}
                error={meta && meta.touched && !!(meta.error || meta.submitError)}
            />
            <IconWrapper>{date ? <Clear onClick={() => onChange(null)} /> : <Event />}</IconWrapper>
        </Wrapper>
    );
};

function DateInput(props) {
    const { label } = props;
    const { input, meta } = useInput(props);
    const [date, setDate] = useState(input && input.value ? new Date(input.value) : null);

    const onChange = (date) => {
        setDate(date);
        if (moment(date).isValid()) {
            input.onChange(new Date(date.toISOString()));
            props.onChange && props.onChange(new Date(date.toISOString()));
        } else {
            input.onChange('');
            props.onChange && props.onChange('');
        }
    };

    return (
        <Labeled label={label} meta={meta}>
            <Datetime
                value={date}
                onChange={onChange}
                locale={moment.locale()}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                renderInput={(props) => <CustomInput {...props} date={date} meta={meta} onChange={onChange} />}
            />
        </Labeled>
    );
}

export default DateInput;
