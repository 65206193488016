import { Card as MaterialCard, Box, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    main: (props: Props) => ({
        overflow: 'inherit',
        padding: 16,
        // background: `url(${theme.palette.type === 'dark' ? cartoucheDark : cartouche}) no-repeat`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
        },
    }),
    title: {},
}));

function Card(props) {
    const { icon, title, subtitle, to } = props;
    const classes = useStyles(props);
    return (
        <MaterialCard className={classes.card}>
            <Link to={to}>
                <div className={classes.main}>
                    <Box width="3em" className="icon">
                        {icon}
                    </Box>
                    <Box textAlign="right">
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                        >
                            {title}
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {subtitle}
                        </Typography>
                    </Box>
                </div>
            </Link>
        </MaterialCard>
    );
}

export default Card
