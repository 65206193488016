import React from 'react';
import { Edit } from 'react-admin';
import Form from './Form';

const AreaEdit = (props) => {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <Form {...props} />
        </Edit>
    );
};
export default AreaEdit;
