import React, { useCallback } from 'react';
import classnames from 'classnames';
import { List, Filter, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Route, useHistory } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import Edit from './edit.react';
import ListDesktop from './ListDesktop';

const Filters = (props) => {
    return (
        <Filter {...props}>
            <SelectInput
                source="status"
                choices={[
                    { id: 0, name: 'Pending' },
                    { id: 1, name: 'Active' },
                    { id: 2, name: 'Disabled' },
                ]}
            />
            <ReferenceInput source="listingId" reference="listings">
                <AutocompleteInput optionText="title" />
            </ReferenceInput>
        </Filter>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

const CommentsList = (props) => {
    const classes = useStyles();

    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/comments');
    }, [history]);

    return (
        <div className={classes.root}>
            <Route path="/comments/:id">
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <React.Fragment>
                            <List
                                {...props}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                filters={<Filters />}
                            >
                                <ListDesktop selectedRow={isMatch ? parseInt(match.params.id, 10) : undefined} />
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <Edit
                                        id={match.params.id}
                                        onCancel={handleClose}
                                        resource={props.resource}
                                        basePath={props.basePath}
                                    />
                                ) : null}
                            </Drawer>
                        </React.Fragment>
                    );
                }}
            </Route>
        </div>
    );
};

export default CommentsList;
