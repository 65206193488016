import { useEffect, useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import axios from 'axios';
import config from '../config';

const DiscountCodes = (props) => {
    const [discountCodes, setDiscountCodes] = useState([]);
    const { record } = props;
    const t = useTranslate();
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        async function fetchEventCoupons() {
            try {
                const { data: eventCoupons } = await axios.get(`${config.apiUrl}/eventcoupons`, {
                    params: { couponId: record.id },
                });
                if (eventCoupons && eventCoupons.length > 0) {
                    const { data: discountCodes } = await axios.get(`${config.apiUrl}/discountcodes`, {
                        params: { eventCouponId: eventCoupons.map((ec) => ec.id) },
                    });
                    if (discountCodes && discountCodes.length > 0) {
                        setDiscountCodes(discountCodes);
                    }
                }
            } catch (error) {}
        }
        fetchEventCoupons();
    }, [record]);

    const handleChange = (e) => {
        const value = Number(e.target.value);
        if (selected.indexOf(value) > -1) {
            setSelected(selected.filter((id) => id !== value));
        } else {
            setSelected([...selected, value]);
        }
    };

    // const handleCopyClick = () => {
    //     if (window.confirm('Are you sure you want to copy the selected discount codes?')) {
    //         axios.post(`${config.apiUrl}/discountcodes/copy`, { discountCodeIds: selected });
    //     }
    // };

    return discountCodes.length > 0 ? (
        <div style={{ padding: '0 20px 30px 20px' }}>
            <Toolbar>
                <Typography variant="h6" id="tableTitle" component="div" style={{ flex: '1 1 100%' }}>
                    {t('resources.discountcodes.name', { smart_count: 2 })}
                </Typography>

                {/* {selected.length > 0 && (
                    <Tooltip title={t('resources.discountcodes.copyDiscountCodes')}>
                        <IconButton aria-label="copy discount codes" onClick={handleCopyClick}>
                            <FileCopy />
                        </IconButton>
                    </Tooltip>
                )} */}
            </Toolbar>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                checked={discountCodes.every((c) => selected.indexOf(c.id) > -1)}
                                onChange={() => {
                                    if (discountCodes.every((c) => selected.indexOf(c.id) > -1)) {
                                        setSelected([]);
                                    } else {
                                        setSelected(discountCodes.map((c) => c.id));
                                    }
                                }}
                            />
                        </TableCell>
                        <TableCell>{t('resources.discountcodes.fields.code')}</TableCell>
                        <TableCell>{t('resources.discountcodes.fields.discount')}</TableCell>
                        <TableCell>{t('common.status')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {discountCodes.map((c) => (
                        <TableRow key={c.id}>
                            <TableCell>
                                <Checkbox
                                    checked={selected.indexOf(c.id) !== -1}
                                    onChange={handleChange}
                                    value={c.id}
                                />
                            </TableCell>
                            <TableCell>{c.hashCode}</TableCell>
                            <TableCell>{c.discountPercentage}%</TableCell>
                            <TableCell>{t(`resources.discountcodes.fields.status.${c.status}`)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    ) : null;
};

export default DiscountCodes;
