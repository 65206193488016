import { List, Datagrid, TextField } from 'react-admin';

const ArticleCategoriesList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="title" label="common.title" />
            </Datagrid>
        </List>
    );
};

export default ArticleCategoriesList;
