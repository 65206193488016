import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const EditTag = (props) => {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <TextInput source="name" />
            </SimpleForm>
        </Edit>
    );
};
export default EditTag;
