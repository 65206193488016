import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';
// import AuthManager from './authManager';

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?sort=-title&offset=0&limit=10
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

export const fetchClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    // let token = AuthManager.jwt;

    // if (!token) {
    //     try {
    //         token = await AuthManager.getRefreshedToken();
    //     } catch (e) {}
    // }

    const token = localStorage.getItem('token');

    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }
    return fetchUtils.fetchJson(url, options);
};

const provider = (apiUrl, httpClient = fetchClient) => ({
    getList: (resource, params) => {
        let { filter } = params;
        filter = Object.keys(filter).reduce((acc, key) => {
            const split = key.split('_');

            if (split.length === 1) {
                acc[key] = filter[key];
            } else {
                const joined = `${split[1]}:${filter[key]}`;
                if (acc[split[0]]) {
                    if (Array.isArray(acc[split[0]])) {
                        acc[split[0]] = [...acc[split[0]], joined];
                    } else {
                        acc[split[0]] = [acc[split[0]], `${joined}`];
                    }
                } else {
                    acc[split[0]] = `${joined}`;
                }
            }

            return acc;
        }, {});

        filter = fetchUtils.flattenObject(filter);

        if (resource === 'auditlogevents') {
            if (filter['author.fullName']) {
                filter['author.lastName'] = filter['author.fullName'];
                delete filter['author.fullName'];
            }
        }

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const orderSign = order === 'ASC' ? '' : '-';

        const query = {
            ...filter,
            sort: field ? `${orderSign}${field}` : undefined,
            offset: (page - 1) * perPage,
            limit: perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }

            return {
                data: json,
                total: parseInt(headers.get('x-total-count')),
            };
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const orderSign = order === 'ASC' ? '' : '-';

        const query = {
            [params.target]: params.id,
            sort: `${orderSign}${field}`,
            offset: (page - 1) * perPage,
            limit: perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: json,
                total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
            };
        });
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) => {
        if (resource === 'auditlogevents') {
            params.data.authorId = params.data.author.id;
            delete params.data.author;
        }

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
});

export default provider;
