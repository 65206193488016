import config from '../../config';
import { fetchClient } from '../../jsonServerProvider';

export async function signAndUpload({ resource, onProgress, path }) {
    const { signedUrl, publicUrl } = await sign(resource, path);

    return upload(resource, signedUrl, publicUrl, onProgress);
}

function sign(resource, path) {
    return fetchClient(`${config.apiUrl}/s3/sign?objectName=${resource.name}&path=${path}`).then(({ json }) => json);
}

function upload(resource, signedUrl, publicUrl, onProgress) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrl);
        xhr.setRequestHeader('x-amz-acl', 'public-read');

        xhr.upload.onprogress = (evt) => {
            if (evt.lengthComputable && onProgress) {
                onProgress((evt.loaded / evt.total) * 100);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve({ url: config.apiUrl + publicUrl });
            }
        };

        xhr.onerror = (err) => {
            reject(err);
            console.error('Could not upload file. ' + err);
        };

        xhr.send(resource);
    });
}
