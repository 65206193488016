import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
    max-width: 600px;
`;

const Percent = styled.div`
    font-size: 16px;
`;

const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 2px solid transparent;
    border-left: 2px solid #84c5e7;
    border-right: 2px solid #84c5e7;
    border-radius: 50%;
    animation: rotate linear 1s infinite both;
`;

const Loading = ({ percentage }) => {
    return (
        <Wrapper>
            <Loader>
                <Percent>{percentage}%</Percent>
            </Loader>
        </Wrapper>
    );
};

export default Loading;
