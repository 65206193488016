import React, { useEffect, useReducer, useRef } from 'react';
import { CheckCircle } from '@material-ui/icons';
import { fetchClient } from '../../../jsonServerProvider';
import config from '../../../config';
import { useInfiniteScroll, useLazyLoading } from '../../../hooks';
import * as Styled from './styles';

const useFetch = (data, dispatch) => {
    useEffect(() => {
        if (!data.firstTime && !data.IsTruncated) {
            return;
        }

        dispatch({ type: 'FETCHING_IMAGES', fetching: true });

        fetchClient(
            `${config.apiUrl}/s3/list?max=1000${data.NextContinuationToken ? `&next=${data.NextContinuationToken}` : ''}`
        )
            .then(({ json }) => {
                dispatch({
                    type: 'STACK_IMAGES',
                    images: json.Contents,
                    NextContinuationToken: json.NextContinuationToken,
                    IsTruncated: json.IsTruncated,
                });
                dispatch({ type: 'FETCHING_IMAGES', fetching: false });
            })
            .catch((e) => {
                // handle error
                dispatch({ type: 'FETCHING_IMAGES', fetching: false });
                return e;
            });
    }, [dispatch, data.NextContinuationToken, data.IsTruncated, data.firstTime]);
};

const Images = React.forwardRef(({ files, setFiles, multiple, ...props }, ref) => {
    const imgReducer = (state, action) => {
        switch (action.type) {
            case 'STACK_IMAGES':
                return {
                    ...state,
                    images: state.images.concat(action.images),
                    IsTruncated: action.IsTruncated,
                    NextContinuationToken: action.NextContinuationToken,
                };
            case 'FETCHING_IMAGES':
                return { ...state, fetching: action.fetching };
            default:
                return state;
        }
    };

    const [imgData, imgDispatch] = useReducer(imgReducer, {
        images: [],
        fetching: true,
        NextContinuationToken: null,
        IsTruncated: null,
    });

    const pageReducer = (state, action) => {
        switch (action.type) {
            case 'ADVANCE_PAGE':
                return {
                    ...state,
                    firstTime: false,
                    NextContinuationToken: imgData.NextContinuationToken,
                    IsTruncated: imgData.IsTruncated,
                };
            default:
                return state;
        }
    };

    const [pager, pagerDispatch] = useReducer(pageReducer, { firstTime: true, page: null });
    let bottomBoundaryRef = useRef(null);
    const classes = Styled.useStyles();
    useFetch(pager, imgDispatch);
    useLazyLoading('.stored-image', imgData.images);
    useInfiniteScroll(bottomBoundaryRef, pagerDispatch);

    const handleImageClick = (image) => {
        if (multiple) {
            setFiles((prevFiles) => {
                if (prevFiles.includes(image.Key)) {
                    return prevFiles.filter((file) => file !== image.Key);
                }
                return [...prevFiles, image.Key];
            });
        } else {
            setFiles([image.Key]);
        }
    };

    return (
        <Styled.Wrapper ref={ref} {...props}>
            <Styled.ImagesWrapper>
                {imgData.images.map((image, index) => {
                    return (
                        <Styled.ImageWrapper key={index} onClick={() => handleImageClick(image)}>
                            <img
                                alt=""
                                data-src={`${config.apiUrl}/s3/uploads/${image.Key}`}
                                className="stored-image"
                                src={'https://picsum.photos/150/150/?blur=10&grayscale'}
                            />
                            {files.includes(image.Key) && <CheckCircle className={classes.checkIcon} />}
                        </Styled.ImageWrapper>
                    );
                })}
            </Styled.ImagesWrapper>
            {imgData.fetching && (
                <div className="text-center bg-secondary m-auto p-3">
                    <p className="m-0 text-white">Getting images</p>
                </div>
            )}
            <div ref={bottomBoundaryRef}></div>
        </Styled.Wrapper>
    );
});

export default Images;
