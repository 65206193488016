import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import omit from 'lodash/omit';
import * as Styled from './styles';

function ValidateReservation() {
    const [hash, setHash] = useState('');
    const dataProvider = useDataProvider();
    const [claimedCoupon, setClaimedCoupon] = useState();
    const [coupon, setCoupon] = useState();
    const [loading, setLoading] = useState(false);
    const [validating, setValidating] = useState(false);
    const t = useTranslate();
    const notify = useNotify();

    const fetchCoupon = async () => {
        setLoading(true);
        setClaimedCoupon(null);
        setCoupon(null);
        try {
            const { data: cllaimedCoupons } = await dataProvider.getList('claimedcoupons', {
                pagination: { page: 1, perPage: 100 },
                sort: {},
                filter: { hashCode: hash },
            });
            if (cllaimedCoupons.length > 0) {
                setClaimedCoupon(cllaimedCoupons[0]);
                setClaimedCoupon(cllaimedCoupons[0]);

                setClaimedCoupon(cllaimedCoupons[0]);

                const { data: coupons } = await dataProvider.getList('coupons', {
                    pagination: { page: 1, perPage: 100 },
                    sort: {},
                    filter: { id: cllaimedCoupons[0].couponId },
                });
                setCoupon(coupons[0]);
            } else {
                notify('couponValidation.notFound', 'warning');
            }
        } catch (error) {
            notify('upload.generalError', 'warning');
        }
        setLoading(false);
    };

    const validateCoupon = async () => {
        setValidating(true);
        try {
            const { data: updatedClaimedCoupon } = await dataProvider.update('claimedcoupons', {
                id: claimedCoupon.id,
                data: { ...omit(claimedCoupon, ['coupon']), validatedAt: moment.utc() },
            });
            setClaimedCoupon(updatedClaimedCoupon);
            notify('couponValidation.validationSuccess', 'success');
        } catch (error) {
            notify('upload.generalError', 'warning');
        }
        setValidating(false);
    };

    return (
        <Styled.Wrapper>
            <Styled.InputWrapper>
                <TextField label="Κωδικός" value={hash} onChange={(e) => setHash(e.target.value)} />

                <Button
                    disabled={loading || !hash || hash.length !== 9}
                    color="primary"
                    variant="contained"
                    onClick={fetchCoupon}
                >
                    {t('couponValidation.search')}
                </Button>
            </Styled.InputWrapper>

            {coupon && claimedCoupon && (
                <Styled.TableWrapper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('resources.claimedcoupons.fields.hashCode')}</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>{t('common.name')}</TableCell>
                                <TableCell>{t('common.phone')}</TableCell>
                                <TableCell>{t('common.status')}</TableCell>
                                <TableCell>{t('resources.coupons.name', 1)}</TableCell>
                                {claimedCoupon.seat && <TableCell>{t('resources.claimedcoupons.seat')}</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {claimedCoupon.hashCode}
                                </TableCell>
                                <TableCell>{claimedCoupon.email}</TableCell>
                                <TableCell>{claimedCoupon.name}</TableCell>
                                <TableCell>{claimedCoupon.phone}</TableCell>
                                <TableCell>
                                    {claimedCoupon.validatedAt ? t('common.isApproved') : t('common.pending')}
                                </TableCell>
                                <TableCell>{coupon.title}</TableCell>
                                {claimedCoupon.seat && <TableCell>{claimedCoupon.seat.name}</TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>

                    {!claimedCoupon.validatedAt && (
                        <Styled.ButtonWrapper>
                            <Button disabled={validating} color="primary" variant="contained" onClick={validateCoupon}>
                                {t('common.validate')}
                            </Button>
                        </Styled.ButtonWrapper>
                    )}
                </Styled.TableWrapper>
            )}
        </Styled.Wrapper>
    );
}

export default ValidateReservation;
