import React, { useState } from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { useNotify, useTranslate } from 'react-admin';
import config from '../config';
import { fetchClient } from '../jsonServerProvider';

function CreateSeller({ record }) {
    const [email, setemail] = useState('');
    const [business_title, setbusiness_title] = useState('');
    const [contact_phone, setcontact_phone] = useState('');
    const [business_name, setbusiness_name] = useState('');
    const [business_tax_number, setbusiness_tax_number] = useState('');
    const [description, setdescription] = useState('');
    const [business_address, setbusiness_address] = useState('');
    const [bank_account_beneficiary, setbank_account_beneficiary] = useState('');
    const [bank_account_iban, setbank_account_iban] = useState('');
    const [errors, setErrors] = useState({});
    const notify = useNotify();
    const t = useTranslate();

    const upload = () => {
        const errors = {};

        if (!email) {
            errors['email'] = 'Υποχρεωτικό πεδίο.';
        }
        if (!business_title) {
            errors['business_title'] = 'Υποχρεωτικό πεδίο.';
        }
        if (!contact_phone) {
            errors['contact_phone'] = 'Υποχρεωτικό πεδίο.';
        }
        if (!business_name) {
            errors['business_name'] = 'Υποχρεωτικό πεδίο.';
        }
        if (!business_tax_number) {
            errors['business_tax_number'] = 'Υποχρεωτικό πεδίο.';
        }
        if (!description) {
            errors['description'] = 'Υποχρεωτικό πεδίο.';
        }
        if (!business_address) {
            errors['business_address'] = 'Υποχρεωτικό πεδίο.';
        }
        if (!bank_account_beneficiary) {
            errors['bank_account_beneficiary'] = 'Υποχρεωτικό πεδίο.';
        }
        if (!bank_account_iban) {
            errors['bank_account_iban'] = 'Υποχρεωτικό πεδίο.';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            fetchClient(`${config.apiUrl}/payments/sellers`, {
                method: 'POST',
                body: JSON.stringify({
                    userId: record.id,
                    payload: {
                        email,
                        business_title,
                        contact_phone,
                        business_name,
                        business_tax_number,
                        description,
                        business_address,
                        bank_account_beneficiary,
                        bank_account_iban,
                    },
                }),
            })
                .then((res) => {
                    notify('upload.uploadSuccess', 'success');
                })
                .catch((err) => {
                    notify(err.message, 'warning');
                });
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    style={{ width: '70%' }}
                    label={t('resources.users.kyc.business_name')}
                    error={errors['business_name']}
                    onChange={(e) => setbusiness_name(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: '70%' }}
                    label={t('resources.users.kyc.business_title')}
                    error={errors['business_title']}
                    onChange={(e) => setbusiness_title(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: '70%' }}
                    label={t('resources.users.kyc.description')}
                    error={errors['description']}
                    onChange={(e) => setdescription(e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: '70%' }}
                    label="Email"
                    error={errors['email']}
                    onChange={(e) => setemail(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: '70%' }}
                    label={t('resources.users.kyc.business_tax_number')}
                    error={errors['business_tax_number']}
                    onChange={(e) => setbusiness_tax_number(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: '70%' }}
                    label={t('resources.users.kyc.business_address')}
                    error={errors['business_address']}
                    onChange={(e) => setbusiness_address(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: '70%' }}
                    label={t('resources.users.kyc.contact_phone')}
                    error={errors['contact_phone']}
                    onChange={(e) => setcontact_phone(e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: '70%' }}
                    label={t('resources.users.kyc.bank_account_beneficiary')}
                    error={errors['bank_account_beneficiary']}
                    onChange={(e) => setbank_account_beneficiary(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: '70%' }}
                    label={t('resources.users.kyc.bank_account_iban')}
                    error={errors['bank_account_iban']}
                    onChange={(e) => setbank_account_iban(e.target.value)}
                />
            </Grid>

            <Grid item xs={12}>
                <Button color="primary" variant="contained" startIcon={<CloudUpload />} onClick={upload}>
                    {t('resources.users.kyc.createSeller')}
                </Button>
            </Grid>
        </Grid>
    );
}

export default CreateSeller;
