import {
    List,
    Datagrid,
    TextField,
    Show,
    SimpleShowLayout,
    RichTextField,
    Filter,
    SearchInput,
    ReferenceInput,
    AutocompleteInput,
    useGetIdentity,
} from 'react-admin';
import { Check, Clear } from '@material-ui/icons';
import DateField from '../fields/dateField';

const Filters = (props) => {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <ReferenceInput source="eventCategoryId" reference="eventcategories">
                <AutocompleteInput optionText="title" />
            </ReferenceInput>
        </Filter>
    );
};

const Content = (props) => {
    const { role } = props;
    return props.record ? (
        <Show
            {...props}
            /* disable the app title change when shown */
            title=" "
        >
            <SimpleShowLayout>
                {props.record.events.length > 0 ? (
                    <List
                        actions={<></>}
                        filter={{ eventId: props.record.id }}
                        sort={{ field: 'startsAt', order: 'ASC' }}
                    >
                        <Datagrid optimized rowClick="edit">
                            {role !== 'editor' && <TextField source="id" label="id" />}
                            <TextField source="title" label="common.title" />
                            <TextField source="venue" />
                            <DateField source="startsAt" label="common.startsAt" showTime />
                            <DateField source="endsAt" label="common.endsAt" showTime />
                        </Datagrid>
                    </List>
                ) : (
                    <RichTextField source="description" />
                )}
            </SimpleShowLayout>
        </Show>
    ) : null;
};

const HasMultipleEvents = ({ record }) => {
    return record && record.events ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {record.events.length > 0 ? <Check /> : <Clear />}
        </div>
    ) : null;
};

const EventsList = (props) => {
    const { identity, loading: identityLoading } = useGetIdentity();
    const { permissions: { role } = { role: 'editor' } } = props;

    const filter = { eventId: null };
    if (role === 'editor' && identity) {
        filter['listing.clientId'] = identity.id;
    }

    return (
        !identityLoading && (
            <List
                {...props}
                filters={role !== 'editor' ? <Filters /> : null}
                filter={filter}
                sort={{ field: 'createdAt', order: 'DESC' }}
            >
                <Datagrid optimized expand={<Content role={role} />} rowClick="edit">
                    {role !== 'editor' && <TextField source="id" label="id" />}
                    <TextField source="title" label="common.title" />
                    <DateField source="startsAt" label="common.startsAt" showTime />
                    <DateField source="endsAt" label="common.endsAt" showTime />
                    <DateField source="createdAt" label="common.createdAt" showTime />
                    <HasMultipleEvents label="resources.events.fields.multipleDates" />
                </Datagrid>
            </List>
        )
    );
};

export default EventsList;
