// import { useStyles } from './styles';
import Images from './images.react';
import Modal from '../../../common/modal';

const MediaLibraryModal = ({ open, handleClose, files, setFiles, multiple }) => {
    return (
        <Modal open={open} title="Media Library" onClose={handleClose} onSave={handleClose}>
            <Images files={files} setFiles={setFiles} multiple={multiple} />
        </Modal>
    );
};

export default MediaLibraryModal;
