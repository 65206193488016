import React from 'react';
import { Create } from 'react-admin';
import Form from './Form';

const CreateListing = (props) => {
    return (
        <Create {...props}>
            <Form {...props} formFor="create" />
        </Create>
    );
};

export default CreateListing;
