import List from './list.react';
import Create from './create.react';
import Edit from './edit.react';

const resource = (ability) => ({
    list: ability.can('read', 'Event') ? List : null,
    create: ability.can('create', 'Event') ? Create : null,
    edit: ability.can('update', 'Event') ? Edit : null,
});

export default resource;
