import { SimpleForm, TextInput } from 'react-admin';

function Form(props) {
    return (
        <SimpleForm {...props}>
            <TextInput source="title" label="common.title" />
        </SimpleForm>
    );
}

export default Form;
