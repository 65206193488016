import React from 'react';
import {
    useEditController,
    EditContextProvider,
    useTranslate,
    TextInput,
    SimpleForm,
    SelectInput,
    DateField,
} from 'react-admin';
import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ReviewEditToolbar from './ReviewEditToolbar';
import StarRatingField from '../fields/starrating';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));

const ReviewEdit = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    if (!controllerProps.record) {
        return null;
    }
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Box fontWeight={500}>
                    <Typography variant="p">{translate('resources.comments.detail')}</Typography>
                </Box>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <EditContextProvider value={controllerProps}>
                <SimpleForm
                    className={classes.form}
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    version={controllerProps.version}
                    redirect="list"
                    toolbar={<ReviewEditToolbar />}
                >
                    {/* <CustomerReferenceField formClassName={classes.inlineField} /> */}
                    {/* <ProductReferenceField formClassName={classes.inlineField} /> */}
                    <DateField
                        source="createdAt"
                        label="common.createdAt"
                        locales="el-EL"
                        formClassName={classes.inlineField}
                    />
                    <StarRatingField
                        source="stars"
                        label="resources.comments.fields.stars"
                        formClassName={classes.inlineField}
                    />
                    <SelectInput
                        source="status"
                        label="common.status"
                        choices={[
                            { id: 0, name: 'Pending' },
                            { id: 1, name: 'Active' },
                            { id: 2, name: 'Disabled' },
                        ]}
                        fullWidth
                    />
                    <TextInput
                        source="content"
                        label="resources.comments.fields.content"
                        rowsMax={15}
                        multiline
                        fullWidth
                    />
                </SimpleForm>
            </EditContextProvider>
        </div>
    );
};

export default ReviewEdit;
