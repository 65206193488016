import { EditableDatagrid, RowForm } from '@kidsproject/ra-editable-datagrid';
import React from 'react';
import { ReferenceManyField, TextField, DateInput, ReferenceField } from 'react-admin';

const ClaimedCouponForm = (props) => (
    <RowForm {...props}>
        <TextField source="hashCode" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="name" />
        <ReferenceField source="seatId" reference="seats" link={false}>
            <TextField source="name" />
        </ReferenceField>
        <DateInput source="validatedAt" />
    </RowForm>
);

const ClaimedCouponStatus = ({ record }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {record.validatedAt ? 'Επαληθεύτηκε' : 'Δεν Επαληθεύτηκε'}
        </div>
    );
};

const ClaimedCouponReferenceInput = (props) => {
    return (
        <div>
            <div>
                <ReferenceManyField
                    reference="claimedcoupons"
                    target="coupon.couponevent.eventId"
                    perPage={100}
                    sort={{ field: 'createdAt', order: 'DESC' }}
                    fullWidth
                >
                    <EditableDatagrid editForm={<ClaimedCouponForm />} noDelete>
                        <TextField source="hashCode" />
                        <TextField source="email" />
                        <TextField source="phone" />
                        <TextField source="name" />
                        <ReferenceField source="seatId" reference="seats" link={false}>
                            <TextField source="name" />
                        </ReferenceField>

                        <ClaimedCouponStatus label="common.status" />
                        <ReferenceField source="couponId" reference="coupons">
                            <TextField source="title" />
                        </ReferenceField>
                    </EditableDatagrid>
                </ReferenceManyField>
            </div>
        </div>
    );
};

export default ClaimedCouponReferenceInput;
