import { Create } from 'react-admin'
import Form from './Form'

export default function CreateEventCategory(props) {
    return (
        <Create {...props}>
            <Form />
        </Create>
    )
}
