import List from './List';
import Create from './Create';
import Edit from './Edit';

const resource = (ability) => ({
    create: ability.can('create', 'EducationalArticle') ? Create : null,
    edit: ability.can('update', 'EducationalArticle') ? Edit : null,
    list: ability.can('read', 'EducationalArticle') ? List : null,
});

export default resource;
