import React, { useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import StarRatingField from '../../fields/starrating';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    item: {
        display: 'flex',
        paddingRight: 0,
        '@media(max-width: 1000px)': {
            flexDirection: 'column-reverse',
        },
    },
    info: {
        flex: '0 0 20%',
        '& span': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '@media(max-width: 1000px)': {
            flex: 1,
            alignSelf: 'flex-start',
        },
    },
    content: {
        flex: '0 0 70%',
        '@media(max-width: 1000px)': {
            flex: 1,
            alignSelf: 'flex-start',
        },
    },
    action: {
        flex: '0 0 10%',
        '@media(max-width: 1000px)': {
            display: 'none',
        },
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

function ListingTitle({ id }) {
     const dataProvider = useDataProvider();
    const [listing, setListing] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        if (id) {
            dataProvider
                .getOne('listings', { id })
                .then(({ data }) => {
                    setListing(data);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [id, dataProvider]);

    if (loading) return null;
    if (error) return null;
    if (!listing) return null;

    return (
        <span>{listing.title}</span>
    )
};


function PendingCommentsList({ list = [] }) {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Card className={classes.root}>
            <CardHeader title={translate('dashboard.pendingComments')} />
            <List dense={true}>
                {list.map((record) => (
                    <ListItem
                        key={record.id}
                        className={classes.item}
                        button
                        component={Link}
                        to={`/comments/${record.id}`}
                    >
                        <ListItemText
                            className={classes.info}
                            primary={`${new Date(record.updatedAt).toLocaleString('en-GB')}`}
                            secondary={<ListingTitle id={record.listingId} />}
                        />
                        <ListItemText
                            className={classes.content}
                            primary={<StarRatingField record={record} source="stars" />}
                            secondary={`${record.content}`}
                        />
                        <ListItemSecondaryAction className={classes.action}>
                            <span className={classes.cost}>
                                {record.status === 1 ? 'Active' : translate('common.pending')}
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Card>
    );
}

export default PendingCommentsList;
