import React from 'react';
import { Edit, SimpleForm, NumberInput, TextInput, TextField, DateInput } from 'react-admin';

const CouponEdit = (props) => {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <TextInput source="title" />
                <TextInput source="description" fullWidth multiline />
                <DateInput source="expirationDate" locales="fr-FR" />
                <NumberInput source="price" />
                <NumberInput source="count" />
                <NumberInput source="order" />
                <TextField source="remainingCoupons" />
            </SimpleForm>
        </Edit>
    );
};

export default CouponEdit;
