import RaRichTextInput from 'ra-input-rich-text';
import Delta from 'quill-delta';
import Quill from 'quill';
import ImageResize from './modules/ImageResize';
import { signAndUpload } from './upload';
import './styles.css';

const configureQuill = (props) => (quill) => {
    Quill.register('modules/imageResize', ImageResize);
    // Allow divs to be inserted into html editor
    // obtained from issue: https://github.com/quilljs/quill/issues/2040
    const Block = Quill.import('blots/block');
    class Div extends Block {}
    Div.tagName = 'div';
    Div.blotName = 'div';
    Div.allowedChildren = Block.allowedChildren;
    Div.allowedChildren.push(Block);
    Quill.register(Div);

    // Allow class span-xxxxx to be inserted (e.g span-adv1)
    const Parchment = Quill.import('parchment');
    const config = { scope: Parchment.Scope.BLOCK };
    const SpanBlock = new Parchment.Attributor.Class('span-block', 'span', config);
    Quill.register(SpanBlock, true);

    function imageHandler() {
        const toolbar = this.quill.getModule('toolbar');

        let fileInput = toolbar.container.querySelector('input.ql-image[type=file]');

        if (fileInput == null) {
            fileInput = document.createElement('input');
            fileInput.setAttribute('type', 'file');
            fileInput.setAttribute(
                'accept',
                'image/png, image/gif, image/jpeg, image/bmp, image/x-icon, image/svg+xml'
            );
            fileInput.classList.add('ql-image');

            fileInput.addEventListener('change', () => {
                if (fileInput.files != null && fileInput.files[0] != null) {
                    const resource = fileInput.files[0];

                    signAndUpload({
                        resource,
                        onProgress: (progress) => {},
                        path: `${props.resource}/`,
                    })
                        .then((result) => {
                            if (result.url) {
                                const imageUrl = result.url;
                                const range = toolbar.quill.getSelection(true);
                                toolbar.quill.updateContents(
                                    new Delta().retain(range.index).delete(range.length).insert({ image: imageUrl }),
                                    'user'
                                );
                                fileInput.value = '';
                            } else {
                                fileInput.value = '';
                            }
                        })
                        .catch((error) => {
                            console.log('Request failed', error);
                        });
                }
            });
            toolbar.container.appendChild(fileInput);
        }
        fileInput.click();
    }

    quill.getModule('toolbar').addHandler('image', imageHandler);

    if (Quill.prototype.getHTML === undefined) {
        Quill.prototype.getHTML = function () {
            return this.container.firstChild.innerHTML;
        };
    }

    quill.txtArea = document.createElement('textarea');
    quill.txtArea.style.cssText =
        'width: 100%;margin: 0px;background: rgb(29, 29, 29);box-sizing: border-box;color: rgb(204, 204, 204);font-size: 15px;outline: none;padding: 20px;line-height: 24px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;top: 0;bottom: 0;border: none;display:none;resize: none;';

    var htmlEditor = quill.addContainer('ql-custom');
    htmlEditor.appendChild(quill.txtArea);

    quill.on('text-change', (delta, oldDelta, source) => {
        var html = quill.getHTML();
        quill.txtArea.value = html;
    });

    quill.txtArea.value = quill.getHTML();

    quill.getModule('toolbar').addHandler('showHtml', function () {
        if (quill.txtArea.style.display === '') {
            const html = quill.txtArea.value;
            if (html === '<p><br/></p>') {
                quill.html = null;
            } else {
                quill.html = html.replace(new RegExp('<p><br/>', 'g'), '<p>');
            }
            quill.pasteHTML(html);
            quill.updateContents(new Delta(), 'user');
        }
        quill.txtArea.style.display = quill.txtArea.style.display === 'none' ? '' : 'none';
    });
};

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    // [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    // [{ script: 'sub' }, { script: 'super' }],
    // [{ indent: '-1' }, { indent: '+1' }],
    // [{ direction: 'rtl' }],

    // [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ font: [] }],
    [{ align: [] }],

    ['link', 'image'],
    ['showHtml'],
    ['clean'], // remove formatting button
];

const RichTextInput = (props) => (
    <RaRichTextInput
        options={{
            modules: {
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                        showHtml: function () {},
                    },
                },
                imageResize: {
                    modules: ['Resize'],
                },
            },
        }}
        configureQuill={configureQuill(props)}
        {...props}
    />
);

export default RichTextInput;
