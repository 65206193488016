import { useDataProvider, useGetIdentity } from 'ra-core';
import React, { useEffect, useState } from 'react';
import EditorDashboard from './Dashboard';
import Form from './Form';

function DashBoard() {
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const [showForm, setShowForm] = useState(false);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        if (identity) {
            setFetching(true);
            dataProvider
                .getList('userconsents', {
                    pagination: { page: 1, perPage: 0 },
                    sort: {},
                    filter: { userId: identity.id },
                })
                .then(({ total } = {}) => {
                    if (total === 0) {
                        setShowForm(true);
                    }
                    setFetching(false);
                });
        }
    }, [identity, setFetching, dataProvider]);

    if (!identity) {
        return null;
    }
    if (fetching) {
        return null;
    }

    return showForm ? <Form /> : <EditorDashboard />;
}

export default DashBoard;
