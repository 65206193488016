import React from 'react';
import Form from './Form';
import { Create } from 'react-admin';

function CreateSubscription(props) {
    return (
        <Create {...props}>
            <Form {...props} />
        </Create>
    );
}

export default CreateSubscription;
