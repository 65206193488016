import {
    List,
    ChipField,
    Datagrid,
    TextField,
    Filter,
    ReferenceInput,
    SelectInput,
    useGetIdentity,
    SearchInput,
    useTranslate,
} from 'react-admin';
import { Chip } from '@material-ui/core';
import DateField from '../fields/dateField';

const Expiring = ({ label }) => {
    const translate = useTranslate();
    return <Chip style={{ alignSelf: 'center' }} label={translate('dashboard.expiringSubscriptions')} />;
};

const Filters = ({ filterValues, ...rest }) => {
    return (
        <Filter {...rest} filterValues={filterValues}>
            <SearchInput source="q" alwaysOn />
            <SelectInput
                source="status"
                label="common.status"
                choices={[
                    { id: 0, name: 'Not Active' },
                    { id: 1, name: 'Active' },
                ]}
                alwaysOn
                initialValue={1}
            />
            <ReferenceInput
                source="client.referrerId"
                label="common.referrer"
                reference="users"
                filter={{ scope: 'operators' }}
                initialValue={filterValues['client.referrerId']}
                // alwaysOn
            >
                <SelectInput optionText={(user) => (user ? `${user.firstName} ${user.lastName}` : '')} />
            </ReferenceInput>
            <SelectInput
                source="paymentMethod"
                choices={[
                    { id: 'NOT_PAID', name: 'Not Paid' },
                    { id: 'CASH', name: 'Cash' },
                    { id: 'CREDIT_CARD', name: 'Credit Card' },
                    { id: 'BANK_TRANSACTION', name: 'Bank Transaction' },
                ]}
            />
            <Expiring source="scope" defaultValue="expiring" label="dashboard.expiringSubscriptions" />
        </Filter>
    );
};

const SubscriptionsList = (props) => {
    const { identity } = useGetIdentity();
    return (
        <List
            {...props}
            filters={<Filters />}
            filterDefaultValues={identity ? { client: { referrerId: identity.id } } : null}
        >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="title" label="common.title" />
                <ChipField source="paymentMethod" />
                <TextField source="price" />
                <TextField source="notes" label="common.notes" />
                <DateField source="createdAt" label="common.createdAt" />
                <DateField source="updatedAt" label="common.updatedAt" />
                <DateField source="expiresAt" label="common.expiresAt" />
            </Datagrid>
        </List>
    );
};

export default SubscriptionsList;
