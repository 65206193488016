import {
    TextField,
    Datagrid,
    List,
    ReferenceField,
    SearchInput,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    useGetIdentity,
    useTranslate,
} from 'react-admin';
import DateField from '../fields/dateField';

const Filters = (props) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <ReferenceInput source="listing.clientId" label="common.client" reference="users">
                <AutocompleteInput optionText={(user) => (user ? `${user.firstName} ${user.lastName}` : '')} />
            </ReferenceInput>
            <ReferenceInput
                source="listingId"
                reference="listings"
                label={translate('resources.listings.name', { smart_count: 2 })}
            >
                <AutocompleteInput optionText="title" />
            </ReferenceInput>
        </Filter>
    );
};

function CouponsList(props) {
    const { identity } = useGetIdentity();
    const { permissions: { role } = { role: 'editor' } } = props;

    const filter = {};
    if (role === 'editor' && identity) {
        filter['listing.clientId'] = identity.id;
    }

    return identity ? (
        <List {...props} filters={role !== 'editor' ? <Filters /> : null} filter={filter}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="title" />
                <TextField source="description" />
                <DateField source="expirationDate" />
                <TextField source="price" />
                <TextField source="count" />
                <ReferenceField source="listingId" reference="listings">
                    <TextField source="title" />
                </ReferenceField>
            </Datagrid>
        </List>
    ) : null;
}

export default CouponsList;
