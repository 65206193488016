import { Create } from 'react-admin';
import Form from './form.react';

const CreateArticle = (props) => {
    return (
        <Create {...props}>
            <Form {...props} />
        </Create>
    );
};

export default CreateArticle;
