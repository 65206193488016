import AreaList from './list';
import AreaCreate from './create';
import AreaEdit from './edit';

const resource = (ability) => ({
    list: ability.can('read', 'Area') ? AreaList : null,
    create: ability.can('create', 'Area') ? AreaCreate : null,
    edit: ability.can('update', 'Area') ? AreaEdit : null,
});

export default resource;
