import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useInput } from 'ra-core';
import config from '../../config';
// import { signAndUpload } from '../richtextinput/upload';

export const TinyMCEInput = (props) => {
    const editorRef = useRef(null);

    const { input } = useInput(props);

    const onEditorChange = (e) => {
        input.onChange(e);
    };

    const value = input.value || '';

    return (
        <Editor
            apiKey="fl3r0af1fc49rsf6yort0pizt3m7gpqlagmtsvv39728go6p"
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={value}
            onEditorChange={onEditorChange}
            init={{
                height: 500,
                plugins: [
                    'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools contextmenu colorpicker textpattern help',
                ],
                toolbar: `undo redo | formatselect | fontselect | fontsizeselect |
                    image media link code |
                    bold italic underline | forecolor backcolor |
                    alignleft aligncenter alignright alignjustify |
                    bullist numlist outdent indent |
                    removeformat | help`,
                content_style: false,
                entity_encoding: 'raw',
                // automatic_uploads: true,
                file_picker_types: 'image',
                images_upload_handler: function (blobInfo, success, failure, progress) {
                    const xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    xhr.open('POST', `${config.apiUrl}/upload`);
                    xhr.onload = function () {
                        var json;

                        if (xhr.status > 400) {
                            failure('HTTP Error: ' + xhr.status);
                            return;
                        }
                        json = JSON.parse(xhr.responseText);

                        success(`${config.apiUrl}/s3/uploads/${json.files[0].key}`);
                    };
                    const formData = new FormData();
                    formData.append('path', props.resource);
                    formData.append('resources', blobInfo.blob(), blobInfo.filename());
                    xhr.send(formData);
                },
            }}
        />
    );
};

export default TinyMCEInput;
