import {
    List,
    Datagrid,
    TextField,
    useGetIdentity,
    Show,
    SimpleShowLayout,
    RichTextField,
    Filter,
    ReferenceInput,
    ReferenceArrayInput,
    SearchInput,
    AutocompleteArrayInput,
} from 'react-admin';
import TagsField from '../articles/list/tagsField.react';
import DateField from '../fields/dateField';
import UserReferenceField from '../fields/userReferenceField';

const Content = (props) => (
    <Show
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleShowLayout>
            <RichTextField source="content" label="resources.articles.fields.content" />
        </SimpleShowLayout>
    </Show>
);

const Filters = (props) => {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <ReferenceInput source="authorId" reference="users" label="resources.articles.fields.authorId">
                <AutocompleteArrayInput optionText={(user) => (user ? `${user.firstName} ${user.lastName}` : '')} />
            </ReferenceInput>
            <ReferenceArrayInput
                source="articletags.educationalTagId"
                label="resources.articles.fields.tags"
                reference="educationaltags"
            >
                <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
        </Filter>
    );
};

const ArticlesList = (props) => {
    const { permissions: { role, ability } = { role: 'editor' } } = props;
    const { identity, loading: identityLoading } = useGetIdentity();

    const filter = {};
    if (role === 'editor' && identity) {
        filter.authorId = identity.id;
    }

    return (
        !identityLoading && (
            <List
                {...props}
                filter={filter}
                sort={{ field: 'createdAt', order: 'DESC' }}
                filters={role !== 'editor' ? <Filters /> : null}
            >
                <Datagrid expand={<Content />} rowClick="edit">
                    <TextField source="id" />
                    {ability && ability.can('read', 'User') && <UserReferenceField source="authorId" />}
                    <TextField source="title" label="common.title" />
                    <DateField source="createdAt" showTime label="common.createdAt" />
                    <TagsField source="tags" />
                    {/* <TextField source="content" /> */}
                </Datagrid>
            </List>
        )
    );
};

export default ArticlesList;
