import {
    ReferenceInput,
    required,
    TabbedForm,
    FormTab,
    SelectInput,
    TextInput,
    AutocompleteInput,
    DateInput,
    DateField,
} from 'react-admin';

const Form = (props) => (
    <TabbedForm {...props}>
        <FormTab label="summary">
            <TextInput source="title" label="common.title" fullWidth validate={required()} />
            <TextInput source="notes" label="common.notes" fullWidth />
            <SelectInput
                source="paymentMethod"
                choices={[
                    { id: 'NOT_PAID', name: 'Not Paid' },
                    { id: 'CASH', name: 'Cash' },
                    { id: 'CREDIT_CARD', name: 'Credit Card' },
                    { id: 'BANK_TRANSACTION', name: 'Bank Transaction' },
                ]}
                fullWidth
            />
            <TextInput source="price" fullWidth />
            <DateInput source="expiresAt" label="common.expiresAt" fullWidth />
            <SelectInput
                source="status"
                label="common.status"
                choices={[
                    { id: 0, name: 'Not Active' },
                    { id: 1, name: 'Active' },
                ]}
                fullWidth
            />
            <ReferenceInput source="clientId" reference="users" label="common.client">
                <AutocompleteInput optionText={(user) => (user ? `${user.firstName} ${user.lastName}` : '')} />
            </ReferenceInput>
        </FormTab>
        <FormTab label="Miscellaneous">
            <DateField source="createdAt" label="common.createdAt" locales="el-EL" showTime />
            <DateField source="updatedAt" label="common.updatedAt" locales="el-EL" showTime />
        </FormTab>
    </TabbedForm>
);

export default Form;
