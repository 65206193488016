import React, { useState } from 'react';
import { Create } from 'react-admin';
import Form from './Form';

const CreateEvent = (props) => {
    const [startDate, setStartDate] = useState();

    return (
        <Create {...props}>
            <Form {...props} formFor="create" startDate={startDate} setStartDate={setStartDate} />
        </Create>
    );
};

export default CreateEvent;
