import React, { useState } from 'react';
import {
    Box,
    Card,
    Button,
    CardActions,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { useDataProvider, useGetIdentity } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        marginTop: theme.spacing(2),
        marginBottom: '1em',
    },
    actions: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
            flexWrap: 'wrap',
            '& a': {
                marginTop: '1em',
                marginLeft: '0!important',
                marginRight: '1em',
            },
        },
    },
}));

function Form() {
    const [formState, setformState] = useState({
        allowFourthPurpose: false,
        allowContactEmail: false,
        allowContactViber: false,
        allowContactPhone: false,
        allowContactFacebook: false,
    });
    const dataProvider = useDataProvider();
    const { identity } = useGetIdentity();

    const classes = useStyles();
    const onCheckboxChange = (e) => {
        setformState((state) => ({
            ...state,
            [e.target.name]: e.target.checked,
        }));
    };

    const submitForm = () => {
        dataProvider.create('userconsents', { data: { ...formState, userId: identity.id } }).then(() => {
            window.location.reload();
        });
    };

    return identity ? (
        <Card className={classes.root}>
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                        ΕΝΗΜΕΡΩΣΗ & ΔΗΛΩΣΗ ΣΥΝΑΙΝΕΣΗΣ ΑΝΑΦΟΡΙΚΑ ΜΕ ΤΑ ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ
                    </Typography>
                    <Box>
                        <Typography variant="body1" component="p" gutterBottom>
                            Η ατομική επιχείρηση με το διακριτικό τίτλο «KIDS PROJECT», που εδρεύει στον Ν. Πύργο
                            Ιστιαίας, με ΑΦΜ 044690354, ΔΟΥ Χαλκίδας, και διαχειρίζεται την ιστοσελίδα η οποία
                            λειτουργεί στη διεύθυνση www.kidsproject.gr, καλούμενη εφεξής «Ιστοσελίδα», σας ενημερώνει
                            σχετικά με την επεξεργασία των προσωπικών σας δεδομένων:
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom>
                            Η Ιστοσελίδα θα φιλοξενεί επιχειρήσεις ή φυσικά πρόσωπα που δραστηριοποιούνται στον τομέα
                            του παιδιού και του εφήβου. Οι βασικές κατηγορίες ενασχόλησης των επιχειρήσεων είναι: α) η
                            εκπαίδευση, β) η ψυχαγωγία, γ) οι τέχνες, δ) ο αθλητισμός, ε) η υγεία. Θα προσφέρεται
                            αναλυτική παρουσίαση των επιχειρήσεων και των υπηρεσιών τους, εμπλουτισμένα με
                            οπτικοακουστικό υλικό.
                        </Typography>
                    </Box>
                    <Box>
                        <ol>
                            <li>
                                <Typography variant="body1">
                                    Για την καταχώρησή σας στην Ιστοσελίδα, συλλέγουμε από εσάς δεδομένα προσωπικού
                                    χαρακτήρα κατά περίπτωση, όπως: Ονοματεπώνυμο, διεύθυνση, τηλέφωνο επικοινωνίας,
                                    διεύθυνση ηλεκτρονικού ταχυδρομείου, url της ιστεσελίδας σας, youtube url για
                                    βίντεο, φωτογραφικό υλικό, διακριτική ονομασία επιχείρησης.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    Τηρούμε τα αναγκαία μέτρα ασφαλείας για τη διαφύλαξη των προσωπικών σας δεδομένων.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    Αναφορικά με τα προσωπικά σας δεδομένα, έχετε τα εξής δικαιώματα:
                                </Typography>
                                <br />
                                <ul>
                                    <li>
                                        <Typography variant="body1">
                                            Δικαίωμα πρόσβασης στα δεδομένα σας: Το δικαίωμα να γνωρίζετε αν τα δεδομένα
                                            σας υφίστανται επεξεργασία, πώς και για ποιο σκοπό.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            Δικαίωμα διόρθωσης των δεδομένων σας: Το δικαίωμα να ζητήσετε διόρθωση των
                                            προσωπικών σας δεδομένων αν αυτά είναι ανακριβή ή ελλιπή.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            Δικαίωμα διαγραφής των δεδομένων σας ("δικαίωμα στη λήθη"): Το δικαίωμα να
                                            ζητήσετε διαγραφή ή κατάργηση των προσωπικών σας δεδομένων, υπό ορισμένες
                                            προϋποθέσεις.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            Δικαίωμα περιορισμού της επεξεργασίας των δεδομένων σας: Το δικαίωμα να
                                            ζητήσετε τον περιορισμό της επεξεργασίας των προσωπικών σας δεδομένων όταν
                                            συντρέχουν ορισμένες προϋποθέσεις.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            Δικαίωμα στη φορητότητα των δεδομένων σας: Το δικαίωμα σας να ζητήσετε να
                                            αποσταλούν τα στοιχεία σας σε τρίτο.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant="body1">
                                    Όταν υποβάλλετε ένα αίτημα ασκώντας κάποιο από τα παραπάνω δικαιώματα, οφείλουμε να
                                    σας απαντήσουμε εντός 1 μηνός είτε ικανοποιώντας το δικαίωμα, είτε απορρίπτοντας
                                    αιτιολογημένα το αίτημα σας, είτε εξηγώντας τους λόγους καθυστέρησης.
                                </Typography>
                                <Typography variant="body1">
                                    Σε κάθε περίπτωση καθυστέρησης οφείλουμε να απαντήσουμε θετικά ή αρνητικά εντός 3
                                    μηνών από το αίτημα. Για την άσκηση των ανωτέρω δικαιωμάτων, μπορείτε να απευθύνετε
                                    εγγράφως σχετικό αίτημα στο e-mail: support@kidsproject.gr
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    Χρησιμοποιούμε τα προσωπικά σας δεδομένα για τους ακόλουθους σκοπούς:
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography variant="body1">
                                            Να σας παρέχουμε υπηρεσίες και να ανταποκρινόμαστε στα αιτήματά σας σχετικά
                                            με το αντικείμενο στο οποίο αναφέρονται.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            Να επικοινωνούμε μαζί σας σχετικά με τις υπηρεσίες και να σας στέλνουμε
                                            ειδοποιήσεις.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            Να απαντάμε στα αιτήματα και στα ερωτήματά σας.
                                        </Typography>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    Σας επισημαίνουμε ότι για την καλύτερη προβολή της επιχείρησής σας καθόλη τη
                                    διάρκεια της συνεργασίας μας,
                                </Typography>
                                <Typography variant="body1">
                                    έχετε τη δυνατότητα να μας παραχωρείτε φωτογραφίες οι οποίες και θα αναρτώνται στην
                                    Ιστοσελίδα,
                                </Typography>
                                <Typography variant="body1">
                                    αλλά και στα λοιπά μέσα κοινωνικής δικτύωσης (facebook, Instagram κ.α.) τα οποία
                                    διαχειρίζεται το KIDSPROJECT.
                                </Typography>
                                <Typography variant="body1">
                                    Σας υπενθυμίζουμε ότι θα πρέπει να κατέχετε όλα τα δικαιώματα ή/και όλες τις άδεις
                                    σε όποιο περιεχόμενο επιθυμείτε να αναρτηθεί στην Ιστοσελίδα και στα μέσα κοινωνικής
                                    δικτύωσης.
                                </Typography>
                            </li>
                        </ol>
                    </Box>
                    <Box>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="allowFourthPurpose"
                                            checked={formState.allowFourthPurpose}
                                            onChange={onCheckboxChange}
                                            color="primary"
                                        />
                                    }
                                    label="Επικοινωνία μαζί σας για διάφορες ενημερώσεις που αφορούν τις δραστηριότητες της Ιστοσελίδας."
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                    <Box>
                        <Typography variant="body1">
                            Επιλέξτε παρακάτω τον τρόπο ή τους τρόπους με τους οποίους προτιμάτε, να επικοινωνούμε μαζί
                            σας:
                        </Typography>

                        <FormControl component="fieldset">
                            <FormGroup aria-label="position">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="allowContactPhone"
                                            checked={formState.allowContactPhone}
                                            onChange={onCheckboxChange}
                                            color="primary"
                                        />
                                    }
                                    label="τηλέφωνο"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="allowContactEmail"
                                            checked={formState.allowContactEmail}
                                            onChange={onCheckboxChange}
                                            color="primary"
                                        />
                                    }
                                    label="e-mail"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="allowContactViber"
                                            checked={formState.allowContactViber}
                                            onChange={onCheckboxChange}
                                            color="primary"
                                        />
                                    }
                                    label="viber"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="allowContactFacebook"
                                            checked={formState.allowContactFacebook}
                                            onChange={onCheckboxChange}
                                            color="primary"
                                        />
                                    }
                                    label="facebook (messenger)"
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>

                    <CardActions className={classes.actions}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<Save />}
                            onClick={submitForm}
                        >
                            Αποθηκευση
                        </Button>
                    </CardActions>
                </Box>
            </Box>
        </Card>
    ) : null;
}

export default Form;
