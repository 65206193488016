import { List, TextField, TextInput } from 'react-admin';
import { RowForm, EditableDatagrid } from '@kidsproject/ra-editable-datagrid';

const Form = (props) => (
    <RowForm {...props}>
        <TextInput source="series" />
        <TextInput source="sequenceNumber" />
    </RowForm>
);

const InviceSeriesList = (props) => {
    return (
        <List {...props}>
            <EditableDatagrid undoable={false} editForm={<Form />} noDelete>
                <TextField source="series" />
                <TextField source="sequenceNumber" />
            </EditableDatagrid>
        </List>
    );
};

export default InviceSeriesList;
