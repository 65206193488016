import { Ability } from '@casl/ability';
import config from './config';

const authProvider = {
    login: ({ email, password }) => {
        const request = new Request(`${config.apiUrl}/login`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((res) => {
                localStorage.setItem('token', res.token);
                const user = { ...res.user, permissions: res.permissions };
                localStorage.setItem('user', JSON.stringify(user));
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getIdentity: async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));

            if (user) {
                const { id, firstName, image } = user;
                return Promise.resolve({
                    id,
                    fullName: firstName,
                    avatar: image ? `${config.apiUrl}/s3/uploads/${image}` : null,
                });
            }
            return Promise.resolve({});
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));

            if (user) {
                const { permissions, role } = user;
                const ability = new Ability(permissions);

                return Promise.resolve({ ability, role });
            }
            return Promise.resolve({});
        } catch (error) {
            return Promise.reject(error);
        }
    },
};

export default authProvider;
