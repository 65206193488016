import React from 'react';
import { Edit } from 'react-admin';
import Form from '../Form';

const UsersEdit = ({ permissions, ...props }) => {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <Form formFor="edit" {...props} redirect={false} />
        </Edit>
    );
};

export default UsersEdit;
