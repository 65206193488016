import { makeStyles, styled as mStyled } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Modal as DefaultModal } from '@material-ui/core';

export const Wrapper = styled.div`
    max-height: 500px;
    overflow: auto;
`;
export const ImagesWrapper = styled.div`
    padding: 2px;
    display: grid;
    grid-gap: 2px;
    grid-template-columns: 1fr 1fr 1fr;
`;

export const ImageWrapper = styled.div`
    position: relative;
    cursor: pointer;
    padding-top: 80%;
    height: 0;

    img {
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const Modal = mStyled(DefaultModal)(function ({ theme }) {
    return {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    };
});

export const useStyles = makeStyles((theme) => ({
    root: {
        height: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: 'translateZ(0)',
        // The position fixed scoping doesn't work in IE 11.
        // Disable this demo to preserve the others.
        '@media all and (-ms-high-contrast: none)': {
            display: 'none',
        },
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkIcon: {
        position: 'absolute',
        right: '5px',
        bottom: '5px',
        width: '22px',
        height: '22px',
        color: '#2684ff',
        fontSize: '22px',
        animation: '$iconBounce 0.1s linear',
    },
    '@keyframes iconBounce': {
        from: {
            transform: 'scale(0)',
        },
        to: {
            transform: 'scale(0.65)',
        },
    },
}));
