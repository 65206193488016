import List from './list';
import Create from './create';
import Edit from './edit';

const resource = (ability) => ({
    list: ability.can('read', 'City') ? List : null,
    create: ability.can('create', 'City') ? Create : null,
    edit: ability.can('update', 'City') ? Edit : null,
});

export default resource;
