import axios from 'axios';
import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField as MuiTextField,
    FormControl,
    InputLabel,
    Select,
    Checkbox,
    MenuItem,
    ListItemText,
    Input,
    FormControlLabel,
} from '@material-ui/core';
import { Button } from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import { useNotify, useTranslate } from 'react-admin';
import config from '../config';

const CreateDiscountCodesDialog = ({ open, onClose, coupons = [] }) => {
    const t = useTranslate();
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [discountPercentage, setDiscountPercentage] = useState();
    const [quantity, setQuantity] = useState(1);
    const [copyDiscountCodes, setCopyDiscountCodes] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();

    const clear = () => {
        setSelectedCoupons([]);
        setDiscountPercentage();
        setQuantity(1);
        setCopyDiscountCodes(false);
    };

    const handleSubmit = async () => {
        if (window.confirm('Είστε σίγουροι;')) {
            setLoading(true);
            try {
                await Promise.all(
                    selectedCoupons.map((c) =>
                        axios.post(`${config.apiUrl}/discountcodes/generate`, {
                            coupon: coupons.find((coupon) => coupon.id === c),
                            couponId: c,
                            quantity: Number(quantity),
                            discountPercentage: Number(discountPercentage),
                            copyDiscountCodes,
                        })
                    )
                );
                notify('resources.discountcodes.generateSuccess', 'success');
                setLoading(false);
                onClose();
                clear();
                window.location.reload();
            } catch {
                notify('resources.discountcodes.generateError', 'warning');
                setLoading(false);
                onClose();
                clear();
            }
        }
    };

    return coupons.length > 0 ? (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('resources.discountcodes.generate')}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '20px 0', display: 'flex' }}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="demo-mutiple-checkbox-label">
                            {t('resources.coupons.name', { smart_count: 2 })}
                        </InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={selectedCoupons}
                            onChange={(e) => setSelectedCoupons(e.target.value)}
                            input={<Input />}
                            renderValue={(selected) =>
                                selected.map((s) => coupons.find((c) => c.id === s).title).join(', ')
                            }
                        >
                            {coupons.map((c) => (
                                <MenuItem key={c.id} value={c.id}>
                                    <Checkbox checked={selectedCoupons.indexOf(c.id) > -1} />
                                    <ListItemText primary={c.title} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div style={{ padding: '20px 0', display: 'flex' }}>
                    <MuiTextField
                        fullWidth
                        label={t('resources.discountcodes.quantity')}
                        value={quantity}
                        type="number"
                        min={1}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </div>
                <div style={{ padding: '20px 0', display: 'flex' }}>
                    <FormControlLabel
                        label={t('resources.discountcodes.copyDiscountCodesShort')}
                        control={
                            <Checkbox
                                checked={copyDiscountCodes}
                                onChange={(e) => setCopyDiscountCodes(e.target.checked)}
                            />
                        }
                    />
                </div>
                <div style={{ padding: '20px 0', display: 'flex' }}>
                    <MuiTextField
                        fullWidth
                        label={t('resources.discountcodes.fields.discount')}
                        value={discountPercentage}
                        type="number"
                        onChange={(e) => setDiscountPercentage(e.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button label="ra.action.cancel" onClick={onClose}>
                    <IconCancel />
                </Button>
                <Button
                    label="ra.action.save"
                    disabled={loading || quantity < 1 || !discountPercentage || selectedCoupons.length === 0}
                    onClick={handleSubmit}
                >
                    <IconSave />
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
};

export default CreateDiscountCodesDialog;
