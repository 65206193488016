import React from 'react';
import { Datagrid, ReferenceField, TextField, useTranslate } from 'react-admin';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import StarRatingField from '../fields/starrating';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const rowStyle = (selectedRow) => (record) => {
    const theme = useTheme();
    let style = {};
    if (!record) {
        return style;
    }
    if (selectedRow && selectedRow === record.id) {
        style = {
            ...style,
            backgroundColor: theme.palette.action.selected,
        };
    }
    if (record.status === 1)
        return {
            ...style,
            borderLeftColor: green[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.status === 0)
        return {
            ...style,
            borderLeftColor: orange[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.status === 2)
        return {
            ...style,
            borderLeftColor: red[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    return style;
};

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    content: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const ListDesktop = ({ selectedRow, ...props }) => {
    const classes = useListStyles();
    const translate = useTranslate();

    return (
        <Datagrid
            rowClick="edit"
            rowStyle={rowStyle(selectedRow)}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            optimized
            {...props}
        >
            <TextField source="id" />
            <TextField source="email" />
            <TextField source="name" label="common.name" />
            <StarRatingField source="stars" />
            <TextField source="content" cellClassName={classes.content} />
            <TextField source="status" label="common.status" />
            <ReferenceField
                source="listingId"
                reference="listings"
                label={translate('resources.listings.name', { smart_count: 1 })}
            >
                <TextField source="title" />
            </ReferenceField>
        </Datagrid>
    );
};

export default ListDesktop;
