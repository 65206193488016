import React from 'react';
import { isImage } from '../utils';
import styled from 'styled-components';
import { GridListTile, GridListTileBar, IconButton, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

const Img = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: none;
`;

const ImgBar = styled(GridListTileBar)`
    position: absolute;
    background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)';
`;

const useStyles = makeStyles((theme) => ({
    root: { cursor: 'pointer' },
    tile: { paddingTop: '80%', height: 0 },
}));

const File = ({ file, apiRoot, showBiggerImg, deleteFile, fileCoverImg, index, ...props }) => {
    const classes = useStyles();
    const [errorCallbackHaveBeenCalled, setErrorCallbackHaveBeenCalled] = React.useState(false);
    let serverUrl = `${apiRoot}/s3/uploads/${file}`;
    const isImg = isImage(serverUrl);
    const backgroundImg = isImg ? serverUrl : fileCoverImg;

    const handleClickImg = () => {
        if (isImg) {
            showBiggerImg(index);
        }
    };

    return (
        <GridListTile classes={classes} {...props} onClick={handleClickImg}>
            <Img
                src={backgroundImg}
                onError={(event) => {
                    if (!errorCallbackHaveBeenCalled) {
                        event.target.src = fileCoverImg;
                        setErrorCallbackHaveBeenCalled(true);
                    }
                }}
                alt=""
            />
            <ImgBar
                actionIcon={
                    <IconButton
                        aria-label="delete image"
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteFile(file);
                        }}
                    >
                        <Delete color="error" />
                    </IconButton>
                }
            />
        </GridListTile>
    );
};

export default File;
