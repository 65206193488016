import React, { useState } from 'react';
import { TextField, Input, Grid, InputLabel, Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { useNotify } from 'react-admin';
import config from '../config';
import { fetchClient } from '../jsonServerProvider';

function AwsUpload() {
    const [vat, setVat] = useState();
    const [id1, setId1] = useState();
    const [id2, setId2] = useState();
    const [IBAN, setIBAN] = useState();
    const [address, setAddress] = useState();
    const [company, setCompany] = useState();
    const [companyStatute, setCompanyStatute] = useState();
    const [certOfRepresentation, setCertOfRepresentation] = useState();
    const [boardComposition, setBoardComposition] = useState();
    const [errors, setErrors] = useState({});
    const notify = useNotify();

    const upload = () => {
        const errors = {};

        // if (!id1) {
        //     errors['id1'] = 'Υποχρεωτικό πεδίο.';
        // }
        // if (!IBAN) {
        //     errors['IBAN'] = 'Υποχρεωτικό πεδίο.';
        // }
        // if (!address) {
        //     errors['address'] = 'Υποχρεωτικό πεδίο.';
        // }
        // if (!company) {
        //     errors['company'] = 'Υποχρεωτικό πεδίο.';
        // }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            // notify('Test success', 'success');
            const formData = new FormData();
            formData.append('VAT', vat);

            if (id2) {
                formData.append('ID_1', id1);
                formData.append('ID_2', id2);
            } else {
                formData.append('ID', id1);
            }

            formData.append('IBAN', IBAN);
            formData.append('ADDRESS', address);
            formData.append('COMPANY', company);
            formData.append('ΚΑΤΑΣΤΑΤΙΚΟ', companyStatute);
            formData.append('ΠΙΣΤΟΠΟΙΗΤΙΚΟ_ΕΚΠΡΟΣΩΠΗΣΗΣ', certOfRepresentation);
            formData.append('ΣΥΝΘΕΣΗ_ΔΣ', boardComposition);

            fetchClient(`${config.apiUrl}/payments/manual-kyc`, { method: 'POST', body: formData })
                .then((res) => {
                    notify('upload.uploadSuccess', 'success');
                })
                .catch((err) => {
                    if (err.message === 'Only images and pdfs are allowed') {
                        notify('upload.fileTypeError', 'warning');
                    } else {
                        notify('upload.generalError', 'warning');
                    }
                });
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField label="ΑΦΜ" onChange={(e) => setVat(e.target.value)} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <InputLabel error={errors['id1']}>Ταυτότητα 1</InputLabel>
                <Input
                    disabled={!vat || vat.length !== 9}
                    error={errors['id1']}
                    type="file"
                    onChange={(e) => setId1(e.target.files[0])}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputLabel>Ταυτότητα 2</InputLabel>
                <Input disabled={!vat || vat.length !== 9} type="file" onChange={(e) => setId2(e.target.files[0])} />
            </Grid>

            <Grid item xs={12}>
                <InputLabel error={errors['IBAN']}>IBAN</InputLabel>
                <Input
                    disabled={!vat || vat.length !== 9}
                    error={errors['IBAN']}
                    type="file"
                    onChange={(e) => setIBAN(e.target.files[0])}
                />
            </Grid>

            <Grid item xs={12}>
                <InputLabel error={errors['address']}>Αντίγραφο λογαριασμού</InputLabel>
                <Input
                    disabled={!vat || vat.length !== 9}
                    error={errors['address']}
                    type="file"
                    onChange={(e) => setAddress(e.target.files[0])}
                />
            </Grid>

            <Grid item xs={12}>
                <InputLabel error={errors['company']}>ΚΑΔ της επιχείρησης</InputLabel>
                <Input
                    disabled={!vat || vat.length !== 9}
                    error={errors['company']}
                    type="file"
                    onChange={(e) => setCompany(e.target.files[0])}
                />
            </Grid>

            <Grid item xs={12}>
                <InputLabel>Καταστατικό</InputLabel>
                <Input
                    disabled={!vat || vat.length !== 9}
                    type="file"
                    onChange={(e) => setCompanyStatute(e.target.files[0])}
                />
            </Grid>

            <Grid item xs={12}>
                <InputLabel>Πιστοποιητικό εκπροσώπησης</InputLabel>
                <Input
                    disabled={!vat || vat.length !== 9}
                    type="file"
                    onChange={(e) => setCertOfRepresentation(e.target.files[0])}
                />
            </Grid>

            <Grid item xs={12}>
                <InputLabel>Σύνθεση ΔΣ</InputLabel>
                <Input
                    disabled={!vat || vat.length !== 9}
                    type="file"
                    onChange={(e) => setBoardComposition(e.target.files[0])}
                />
            </Grid>

            <Grid item xs={12}>
                <Button
                    disabled={!vat || vat.length !== 9}
                    color="primary"
                    variant="contained"
                    startIcon={<CloudUpload />}
                    onClick={upload}
                >
                    Upload
                </Button>
            </Grid>
        </Grid>
    );
}

export default AwsUpload;
