import React from 'react'
import { Collapse, List, ListItemIcon, makeStyles, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
        '& a': {
            paddingLeft: theme.spacing(4),
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
    },
    sidebarIsClosed: {
        '& a': {
            paddingLeft: theme.spacing(2),
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
    },
}));

function SubMenu({ name, isOpen, icon, children, handleToggle }) {
    const sidebarIsOpen = useSelector(
        state => state.admin.ui.sidebarOpen
    );
    const classes = useStyles();

    const header = (
        <MenuItem button onClick={handleToggle}>
            <ListItemIcon className={classes.icon}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {name}
            </Typography>
        </MenuItem>
    );

    return (
        <>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
                >
                    {children}
                </List>
            </Collapse>
        </>
    );
}

export default SubMenu
