import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { GMapInput } from '../fields/gmap';

const TownCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" label="common.name" />
            <GMapInput source="coordinates" />
        </SimpleForm>
    </Create>
);

export default TownCreate;
