import { Create } from 'react-admin';
import Form from './Form';

const CreatePoster = (props) => {
    return (
        <Create {...props}>
            <Form {...props} />
        </Create>
    );
};

export default CreatePoster;
