import { AutocompleteInput, SimpleForm, required, ReferenceInput, TextInput } from 'react-admin';
import { GMapInput } from '../fields/gmap';

function Form(props) {
    return (
        <SimpleForm {...props}>
            <TextInput source="name" label="common.name" />
            <ReferenceInput
                source="cityId"
                reference="cities"
                validate={[required()]}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <GMapInput source="coordinates" label="common.coordinates" />
            <TextInput source="searchTags" />
        </SimpleForm>
    );
}

export default Form;
