import { List, TextField, Datagrid } from 'react-admin';

const LinkField = ({ record = {}, source }) => <a href={record[source]}>{record[source].substr(0, 40) + '...'}</a>;

function ReceiptsList(props) {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="series" />
                <TextField source="sequenceNumber" />
                <TextField source="value" />
                <TextField source="name" />
                <TextField source="email" />
                <LinkField source="url" />
            </Datagrid>
        </List>
    );
}
export default ReceiptsList;
