import React, { useState, useCallback } from 'react';
import { ReferenceInput, SelectInput, required, TextInput, useInput, AutocompleteInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import QuickCreate from '../common/QuickCreate';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
});

const AreaReferenceInput = (props) => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    const { input } = useInput({ source: 'cityId' });
    const form = useForm();
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    React.useEffect(() => {
        if (input.value !== props.record.cityId) {
            form.change('areaId', undefined);
        }
    }, [form, input.value, props.record.cityId]);

    return (
        <div className={classes.root}>
            <div>
                <ReferenceInput filter={{ cityId: input.value ? input.value : undefined }} key={version} {...props}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            </div>

            <QuickCreate onChange={handleChange} source="areaId" resource="areas">
                <TextInput source="name" validate={required()} fullWidth />
                <ReferenceInput
                    source="cityId"
                    fullWidth
                    reference="cities"
                    validate={[required()]}
                    defaultValue={input.value}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </QuickCreate>
        </div>
    );
};

export default AreaReferenceInput;
