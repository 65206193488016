import React from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import { FilterList, FilterListItem, FilterLiveSearch, useTranslate } from 'react-admin';
import styled from 'styled-components';

const Card = withStyles((theme) => ({
    root: {
        // [theme.breakpoints.up('sm')]: {
        order: -1,
        width: '15em',
        marginRight: '1em',
        // },
        // [theme.breakpoints.down('sm')]: {
        // display: 'none',
        // },
    },
}))(MuiCard);

const Section = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`;

const Title = styled.span`
    font-size: 0.75rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 2.66;
    letter-spacing: 0.08333em;
    text-transform: uppercase;
`;

const Aside = () => {
    const t = useTranslate();
    return (
        <Card>
            <CardContent>
                <FilterLiveSearch />

                <Section>
                    <Title>Email</Title>
                    <FilterLiveSearch source="email" />
                </Section>

                <Section>
                    <Title>{t('resources.listings.fields.phone')}</Title>
                    <FilterLiveSearch source="phone" />
                </Section>
                <Section>
                    <Title>{t('resources.listings.fields.mobilePhone')}</Title>
                    <FilterLiveSearch source="mobilePhone" />
                </Section>

                <FilterList label="resources.users.fields.role" icon={<GroupIcon />}>
                    <FilterListItem label="Admin" value={{ role: 'admin' }} />
                    <FilterListItem label="Operator" value={{ role: 'operator' }} />
                    <FilterListItem label="Editor" value={{ role: 'editor' }} />
                </FilterList>
            </CardContent>
        </Card>
    );
};
export default Aside;
