import { Edit } from "react-admin";
import Form from "./Form";

export default function EditEventCategory(props) {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <Form />
        </Edit>
    );
}
