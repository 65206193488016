import React from 'react';
import {
    AutocompleteArrayInput,
    NumberInput,
    ReferenceArrayInput,
    TextInput,
    SelectInput,
    required,
    FormTab,
    TabbedForm,
    useTranslate,
} from 'react-admin';
import S3FileInput from '../fields/s3-input';
import config from '../config';
import { customReferenceParseFormat } from '../helpers/utils';

function Form(props) {
    const translate = useTranslate();
    return (
        <TabbedForm {...props}>
            <FormTab label="info">
                <TextInput source="title" label="common.title" fullWidth validate={[required()]} />
                <TextInput source="url" fullWidth />
                <SelectInput
                    source="type"
                    choices={[
                        { id: 1, name: 'General' },
                        { id: 2, name: 'Normal' },
                    ]}
                    validate={[required()]}
                />
                <NumberInput source="order" label="common.order" min={0} />
                <ReferenceArrayInput
                    source="categories"
                    label={translate('resources.categories.name', { smart_count: 2 })}
                    reference="categories"
                    {...customReferenceParseFormat}
                >
                    <AutocompleteArrayInput
                        optionText={(category) => (category ? `${category.title} (${category.parent.title})` : '')}
                    />
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="common.images">
                <S3FileInput
                    source="image"
                    apiRoot={config.apiUrl}
                    uploadOptions={{
                        server: config.apiUrl,
                        signingUrl: '/s3/sign',
                    }}
                    label="common.image"
                    validate={[required()]}
                />
                <S3FileInput
                    source="mobileImage"
                    apiRoot={config.apiUrl}
                    uploadOptions={{
                        server: config.apiUrl,
                        signingUrl: '/s3/sign',
                    }}
                    label="common.mobileImage"
                    // validate={[required()]}
                />
            </FormTab>
        </TabbedForm>
    );
}

export default Form;
