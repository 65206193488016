import { List, Datagrid, TextField, Filter, SearchInput } from 'react-admin';

const Filters = ({ filterValues, ...rest }) => {
    return (
        <Filter filterValues={filterValues} {...rest}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};

const TagsList = (props) => {
    return (
        <List filters={<Filters />} {...props}>
            <Datagrid optimized rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
            </Datagrid>
        </List>
    );
};

export default TagsList;
