import React from 'react';
import {
    Datagrid,
    Filter,
    List,
    Pagination,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField,
    useGetIdentity,
} from 'react-admin';

const ListingPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const Filters = ({ filterValues, ...props }) => {
    return (
        <Filter {...props} filterValues={filterValues}>
            <SearchInput source="q" alwaysOn />
            <ReferenceInput
                source="client.referrerId"
                label="common.referrer"
                reference="users"
                filter={{ scope: 'operators' }}
                initialValue={filterValues['client.referrerId']}
            >
                <SelectInput optionText={(user) => (user ? `${user.firstName} ${user.lastName}` : '')} />
            </ReferenceInput>
        </Filter>
    );
};

function ListingsList(props) {
    const { identity, loading } = useGetIdentity();
    const { permissions: { role } = { role: 'editor' } } = props;

    const filterDefaultValues = { client: { referrerId: identity ? identity.id : null } };

    return !loading ? (
        <List
            {...props}
            filters={role !== 'editor' ? <Filters /> : null}
            pagination={<ListingPagination />}
            perPage={50}
            filter={role === 'editor' ? { clientId: identity.id } : {}}
            filterDefaultValues={role !== 'editor' ? filterDefaultValues : {}}
        >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="title" label="common.title" />
                <TextField source="phone" />
                <TextField source="mobilePhone" />
            </Datagrid>
        </List>
    ) : null;
}

export default ListingsList;
