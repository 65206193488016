import List from './list.react';
// import Create from './create.react';
// import Edit from './edit.react';

const resource = (ability) => ({
    list: ability.can('read', 'Comment') ? List : null,
    // create: ability.can('create', 'Comment') ? Create : null,
    // edit: ability.can('update', 'Comment') ? Edit : null,
});

export default resource;
