import React from 'react';
import { NumberInput, required, SelectInput, TextInput } from 'react-admin';
import { CreateNode, EditNode, SimpleForm, TreeWithDetails } from '@kidsproject/ra-tree';
import RichTextInput from '../fields/richtextinput';

const CategoriesTree = (props) => {
    return <TreeWithDetails draggable hideRootNodes {...props} />;
};

const CategoriesCreate = (props) => (
    <CreateNode {...props}>
        <SimpleForm>
            <TextInput source="title" label="common.title" validate={[required()]} fullWidth />
            <RichTextInput source="description" label="resources.categories.fields.description" fullWidth />
            <SelectInput
                source="status"
                label="common.status"
                choices={[
                    { id: 0, name: 'Not Active' },
                    { id: 1, name: 'Active' },
                ]}
            />
            <TextInput source="metaDescription" label="common.metaDescription" fullWidth multiline />
            <TextInput source="metaKeywords" label="common.metaKeywords" fullWidth />
        </SimpleForm>
    </CreateNode>
);

const CategoriesEdit = (props) => (
    <EditNode {...props} undoable={false}>
        <SimpleForm>
            <TextInput source="title" label="common.title" validate={[required()]} fullWidth />
            <RichTextInput source="description" label="resources.categories.fields.description" fullWidth />
            <SelectInput
                source="status"
                label="common.status"
                choices={[
                    { id: 0, name: 'Not Active' },
                    { id: 1, name: 'Active' },
                ]}
            />
            <NumberInput source="order" label="common.order" />
            <TextInput source="metaDescription" label="common.metaDescription" fullWidth multiline />
            <TextInput source="metaKeywords" label="common.metaKeywords" fullWidth />
        </SimpleForm>
    </EditNode>
);

const CategoriesList = (props) => (
    <React.Fragment>
        <CategoriesTree
            create={CategoriesCreate}
            edit={CategoriesEdit}
            showLine={false}
            hideRootNodes={false}
            {...props}
        />
    </React.Fragment>
);

export default CategoriesList;
