import GoogleMapReact from 'google-map-react';
import TextField from '@material-ui/core/TextField';
import { useInput } from 'ra-core';
import { Labeled } from 'react-admin';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Marker from './Marker';
import config from '../../config';

const getPosition = (e) => ({
    lng: e.lng,
    lat: e.lat,
});

export const GMapInput = (props) => {
    const { defaultCenter, defaultZoom, readOnly } = props;
    const { input } = useInput(props);
    const [center, setCenter] = useState(defaultCenter || { lat: 39, lng: 21 });
    const [zoom] = useState(defaultZoom || 6);

    useEffect(() => {
        const coordinates = input.value || null;
        if (coordinates) setCenter(coordinates);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addMarker = useCallback(
        (e) => {
            const position = getPosition(e);
            input.onChange(position);
        },
        [input]
    );

    const deleteMarker = useCallback(() => {
        input.onChange(null);
    }, [input]);

    const onValueChange = useCallback(
        (e) => {
            if (e.target.value === '' && input.value !== null) {
                input.onChange(null);
            } else {
                input.onChange({ ...input.value, [e.target.name]: e.target.value });
            }
        },
        [input]
    );

    useEffect(() => {
        if (input.value.lat && input.value.lng)
            setCenter({ lat: Number(input.value.lat), lng: Number(input.value.lng) });
    }, [input.value]);

    return (
        <Fragment>
            <Labeled label="common.coordinates">
                <div>
                    <TextField
                        type="number"
                        name="lat"
                        label="lat"
                        onChange={onValueChange}
                        value={input.value.lat || ''}
                        style={{ marginRight: '10px' }}
                    />
                    <TextField
                        type="number"
                        name="lng"
                        label="lng"
                        onChange={onValueChange}
                        value={input.value.lng || ''}
                    />
                </div>
            </Labeled>
            <div style={{ height: '500px', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: config.googleMapAPIKey }}
                    defaultCenter={center}
                    center={center}
                    defaultZoom={zoom}
                    onClick={readOnly ? () => {} : addMarker}
                    options={{ disableDoubleClickZoom: true }}
                >
                    {input.value && (
                        <Marker
                            lat={input.value.lat}
                            lng={input.value.lng}
                            onClick={readOnly ? () => {} : deleteMarker}
                        />
                    )}
                </GoogleMapReact>
            </div>
        </Fragment>
    );
};

export const GMapField = (props) => <GMapInput readOnly {...props} />;
