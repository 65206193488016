import { CloneButton, Edit, TopToolbar } from 'react-admin';
import Form from './form.react';

const EditActions = ({ basePath, data }) => (
    <TopToolbar>
        <CloneButton className="button-clone" basePath={basePath} record={data} />
    </TopToolbar>
);

const EditArticle = (props) => {
    return (
        <Edit actions={<EditActions />} {...props} mutationMode="pessimistic">
            <Form {...props} />
        </Edit>
    );
};

export default EditArticle;
