import React from 'react';
import { LanguageSwitcher } from '@kidsproject/ra-preferences';
import { AppBar, Layout, Sidebar, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Search } from '@kidsproject/ra-search';
import { lightTheme } from './themes';
import Menu from './menu';
import logo from './logo.png';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    logoWrapper: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
});

const CustomAppBar = (props) => {
    const { permissions: { role } = { role: 'editor' } } = usePermissions();
    const classes = useStyles();

    return (
        <AppBar {...props}>
            <div className={classes.logoWrapper}>
                <img src={logo} alt="logo" height="40px" />
            </div>
            <LanguageSwitcher
                languages={[
                    { locale: 'el', name: 'Ελληνικά' },
                    { locale: 'en', name: 'English' },
                    // { locale: 'fr', name: 'Français' },
                ]}
            />
            {role !== 'editor' && <Search />}
        </AppBar>
    );
};

const CustomSidebar = (props) => <Sidebar {...props} size={200} />;

const CustomLayout = (props) => {
    return <Layout {...props} sidebar={CustomSidebar} menu={Menu} theme={lightTheme} appBar={CustomAppBar} />;
};

export default CustomLayout;
