import { List, Datagrid, TextField, Filter, SearchInput } from 'react-admin';

const Filters = (props) => {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};

const CityList = (props) => {
    return (
        <List filters={<Filters />} {...props}>
            <Datagrid optimized rowClick="edit">
                <TextField source="id" />
                <TextField source="name" label="common.name" />
            </Datagrid>
        </List>
    );
};

export default CityList;
