export const isImage = (fileName) => {
    const imgExtensions = ['gif', 'png', 'jpg', 'jpeg', 'svg', 'bmp'];
    const fileNameChunks = fileName.split('.');
    return (
        fileNameChunks.length > 1 &&
        imgExtensions.indexOf(fileNameChunks[fileNameChunks.length - 1].toLowerCase()) !== -1
    );
};

export const getFiles = (input, record, source) => {
    if (input && input.value && typeof input.value === 'string') {
        return [input.value];
    }

    if (record && record[source] && typeof record[source] === 'string') {
        return [record[source]];
    }

    if (input) {
        return input.value || [];
    }

    if (record) {
        return record[source] || [];
    }
};
