import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTranslate } from 'react-admin';


// const KYCStatus = {
//     INITIAL: 'initial',
//     PENDING: 'pending',
//     VERIFIED: 'verified',
//     REJECTED: 'rejected',
// };

function EverypayKYCStatus({ record }) {
    const t = useTranslate();
    const { everypayKYC } = record;

    const { status, kyc_process_url, category_status } = everypayKYC;
    const {
        company_info,
        gov_kyc,
        proof_of_id,
        proof_of_funds,
        proof_of_business_establishment,
        proof_of_financials,
        proof_of_residence,
        other_documents,
    } = category_status;

    return (
        <>
            <p>
                {t('resources.users.kyc.state')}{': '}{t(`resources.users.kyc.status.${status}`)}
            </p>
            <a href={kyc_process_url}>{t('resources.users.kyc.processKYC')}</a>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('resources.users.kyc.field')}</TableCell>
                        <TableCell>{t('resources.users.kyc.fieldStatus')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{t(`resources.users.kyc.company_info`)}</TableCell>
                        <TableCell>{t(`resources.users.kyc.status.${company_info.toLowerCase()}`)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t(`resources.users.kyc.gov_kyc`)}</TableCell>
                        <TableCell>{t(`resources.users.kyc.status.${gov_kyc.toLowerCase()}`)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t(`resources.users.kyc.proof_of_id`)}</TableCell>
                        <TableCell>{t(`resources.users.kyc.status.${proof_of_id.toLowerCase()}`)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t(`resources.users.kyc.proof_of_funds`)}</TableCell>
                        <TableCell>{t(`resources.users.kyc.status.${proof_of_funds.toLowerCase()}`)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t(`resources.users.kyc.proof_of_business_establishment`)}</TableCell>
                        <TableCell>{t(`resources.users.kyc.status.${proof_of_business_establishment.toLowerCase()}`)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t(`resources.users.kyc.proof_of_financials`)}</TableCell>
                        <TableCell>{t(`resources.users.kyc.status.${proof_of_financials.toLowerCase()}`)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t(`resources.users.kyc.proof_of_residence`)}</TableCell>
                        <TableCell>{t(`resources.users.kyc.status.${proof_of_residence.toLowerCase()}`)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t(`resources.users.kyc.other_documents`)}</TableCell>
                        <TableCell>{t(`resources.users.kyc.status.${other_documents.toLowerCase()}`)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}

export default EverypayKYCStatus;
