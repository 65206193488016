import { makeStyles } from '@material-ui/core';
import {
    LocationCity,
    LocationOn,
    Description,
    Label,
    Comment,
    Event,
    Contacts,
    Euro,
    PhotoLibrary,
    Payment,
    ConfirmationNumber,
    LocalOffer,
    Group,
    History,
    CloudUpload,
    Receipt,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { DashboardMenuItem, MenuItemLink, useGetIdentity, usePermissions, useTranslate } from 'react-admin';
import SubMenu from './SubMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
    },
}));

function Menu() {
    const [state, setState] = useState({
        citiesMenu: false,
        eventsMenu: false,
        articlesMenu: false,
        couponsMenu: false,
        educationalArticlesMenu: false,
        receiptsMenu: false,
    });
    const { permissions: { role } = { role: 'editor' } } = usePermissions();
    const { identity } = useGetIdentity();
    const translate = useTranslate();
    const classes = useStyles();

    const handleToggle = (menu) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }));
    };

    return identity ? (
        <div className={classes.root}>
            <DashboardMenuItem />

            {role !== 'editor' && (
                <MenuItemLink
                    to="/users"
                    primaryText={translate('resources.users.name', { smart_count: 2 })}
                    leftIcon={<Group />}
                />
            )}
            {role !== 'editor' && (
                <MenuItemLink
                    to="/subscriptions"
                    primaryText={translate('resources.subscriptions.name', { smart_count: 2 })}
                    leftIcon={<Payment />}
                />
            )}
            <MenuItemLink
                to="/listings"
                primaryText={translate('resources.listings.name', { smart_count: 2 })}
                leftIcon={<Contacts />}
            />
            <SubMenu
                handleToggle={() => handleToggle('couponsMenu')}
                isOpen={state.couponsMenu}
                name={translate('resources.coupons.name', { smart_count: 2 })}
                icon={<ConfirmationNumber />}
            >
                <MenuItemLink
                    to="/coupons"
                    primaryText={translate('resources.coupons.name', { smart_count: 2 })}
                    leftIcon={<ConfirmationNumber />}
                />
                <MenuItemLink
                    to="/claimedcoupons"
                    primaryText={translate('resources.claimedcoupons.name', { smart_count: 2 })}
                    leftIcon={<ConfirmationNumber />}
                />
            </SubMenu>
            {role !== 'editor' ? (
                <SubMenu
                    handleToggle={() => handleToggle('eventsMenu')}
                    isOpen={state.eventsMenu}
                    name={translate('resources.events.name', { smart_count: 2 })}
                    icon={<Event />}
                >
                    <MenuItemLink
                        to="/events"
                        primaryText={translate('resources.events.name', { smart_count: 2 })}
                        leftIcon={<Event />}
                    />
                    <MenuItemLink
                        to="/eventcategories"
                        primaryText={translate('resources.eventcategories.name', { smart_count: 2 })}
                        leftIcon={<Label />}
                    />
                </SubMenu>
            ) : (
                <MenuItemLink
                    to="/events"
                    primaryText={translate('resources.events.name', { smart_count: 2 })}
                    leftIcon={<Event />}
                />
            )}
            {role !== 'editor' ? (
                <SubMenu
                    handleToggle={() => handleToggle('articlesMenu')}
                    isOpen={state.articlesMenu}
                    name={translate('resources.articles.name', { smart_count: 2 })}
                    icon={<Description />}
                >
                    <MenuItemLink
                        to="/articles"
                        primaryText={translate('resources.articles.name', { smart_count: 2 })}
                        leftIcon={<Description />}
                    />
                    <MenuItemLink
                        to="/articlecategories"
                        primaryText={translate('resources.articleCategories.name', { smart_count: 2 })}
                        leftIcon={<Label />}
                    />
                    <MenuItemLink to="/tags" primaryText="Tags" leftIcon={<LocalOffer />} />
                </SubMenu>
            ) : (
                <MenuItemLink
                    to="/articles"
                    primaryText={translate('resources.articles.name', { smart_count: 2 })}
                    leftIcon={<Description />}
                />
            )}
            {role !== 'editor' && (
                <SubMenu
                    handleToggle={() => handleToggle('educationalArticlesMenu')}
                    isOpen={state.educationalArticlesMenu}
                    name={translate('resources.educationalarticles.name', { smart_count: 2 })}
                    icon={<Description />}
                >
                    <MenuItemLink
                        to="/educationalarticles"
                        primaryText={translate('resources.articles.name', { smart_count: 2 })}
                        leftIcon={<Description />}
                    />
                    <MenuItemLink
                        to="/educationalcategories"
                        primaryText={translate('resources.educationalcategories.name', { smart_count: 2 })}
                        leftIcon={<Label />}
                    />
                    <MenuItemLink to="/educationaltags" primaryText="Tags" leftIcon={<LocalOffer />} />
                </SubMenu>
            )}
            {role !== 'editor' && (
                <MenuItemLink
                    to="/categories"
                    primaryText={translate('resources.categories.name', { smart_count: 2 })}
                    leftIcon={<Label />}
                />
            )}
            {role !== 'editor' && (
                <SubMenu
                    handleToggle={() => handleToggle('citiesMenu')}
                    isOpen={state.citiesMenu}
                    name={translate('resources.cities.name', { smart_count: 2 })}
                    icon={<LocationCity />}
                >
                    <MenuItemLink
                        to="/cities"
                        primaryText={translate('resources.cities.name', { smart_count: 2 })}
                        leftIcon={<LocationCity />}
                    />
                    <MenuItemLink
                        to="/areas"
                        primaryText={translate('resources.areas.name', { smart_count: 2 })}
                        leftIcon={<LocationOn />}
                    />
                </SubMenu>
            )}
            {role !== 'editor' && (
                <MenuItemLink
                    to="/comments"
                    primaryText={translate('resources.comments.name', { smart_count: 2 })}
                    leftIcon={<Comment />}
                />
            )}
            {role !== 'editor' && (
                <MenuItemLink
                    to="/plans"
                    primaryText={translate('resources.plans.name', { smart_count: 2 })}
                    leftIcon={<Euro />}
                />
            )}
            {role !== 'editor' && (
                <MenuItemLink
                    to="/posters"
                    primaryText={translate('resources.posters.name', { smart_count: 2 })}
                    leftIcon={<PhotoLibrary />}
                />
            )}
            {role !== 'editor' && (
                <MenuItemLink to="/aws-upload" primaryText="Everypay Upload" leftIcon={<CloudUpload />} />
            )}

            {role !== 'editor' && (
                <SubMenu
                    handleToggle={() => handleToggle('receiptsMenu')}
                    isOpen={state.receiptsMenu}
                    name={translate('resources.receipts.name', { smart_count: 2 })}
                    icon={<Description />}
                >
                    <MenuItemLink
                        to="/invoiceseries"
                        primaryText={translate('resources.invoiceseries.name', { smart_count: 1 })}
                        leftIcon={<Receipt />}
                    />
                    <MenuItemLink
                        to="/receipts"
                        primaryText={translate('resources.receipts.name', { smart_count: 2 })}
                        leftIcon={<Receipt />}
                    />
                </SubMenu>
            )}
            {role === 'admin' && identity && (identity.id === 1 || identity.id === 2) && (
                <MenuItemLink to="/auditlogevents" primaryText="AuditLog Events" leftIcon={<History />} />
            )}
            <MenuItemLink to="/validate-reservation" primaryText="Κρατήσεις" leftIcon={<ConfirmationNumber />} />
        </div>
    ) : null;
}

export default Menu;
