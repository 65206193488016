import {
    TextField,
    List,
    ReferenceField,
    SearchInput,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    useGetIdentity,
    DateInput,
} from 'react-admin';
import { RowForm, EditableDatagrid } from '@kidsproject/ra-editable-datagrid';
import DateField from '../fields/dateField';

const Filters = ({ role, identity, ...rest }) => {
    const filter = {};
    if (role === 'editor' && identity) {
        filter['listing.clientId'] = identity.id;
    }

    return (
        <Filter {...rest}>
            <SearchInput source="q" alwaysOn />
            {role !== 'editor' && (
                <ReferenceInput source="coupon.listing.clientId" label="Client" reference="users">
                    <AutocompleteInput optionText={(user) => (user ? `${user.firstName} ${user.lastName}` : '')} />
                </ReferenceInput>
            )}
            {role !== 'editor' && (
                <ReferenceInput source="coupon.listing.id" reference="listings" label="Listing">
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>
            )}
            <ReferenceInput source="couponId" reference="coupons" filter={filter}>
                <AutocompleteInput optionText="description" />
            </ReferenceInput>
        </Filter>
    );
};

const ClaimedCouponForm = (props) => (
    <RowForm {...props}>
        <TextField source="id" />
        <TextField source="hashCode" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="name" />
        <DateInput source="validatedAt" />
        <ReferenceField source="couponId" reference="coupons">
            <TextField source="description" />
        </ReferenceField>
    </RowForm>
);

function ClaimedCouponsList(props) {
    const { identity } = useGetIdentity();
    const { permissions: { role } = { role: 'editor' } } = props;

    const filter = {};
    if (role === 'editor' && identity) {
        filter['coupon.listing.clientId'] = identity.id;
    }

    return identity ? (
        <List
            {...props}
            filters={<Filters role={role} identity={identity} />}
            // filters={role !== 'editor' && <Filters />}
            filter={filter}
        >
            <EditableDatagrid undoable={false} editForm={<ClaimedCouponForm />}>
                <TextField source="id" />
                <TextField source="hashCode" />
                <TextField source="email" />
                <TextField source="phone" label="common.phone" />
                <TextField source="name" label="common.name" />
                <DateField source="validatedAt" />
                <ReferenceField source="couponId" reference="coupons">
                    <TextField source="description" />
                </ReferenceField>
            </EditableDatagrid>
        </List>
    ) : null;
}

export default ClaimedCouponsList;
