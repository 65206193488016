import React from 'react';
import { Edit } from 'react-admin';
import Form from './Form';

function EditEvent(props) {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <Form {...props} formFor="edit" />
        </Edit>
    );
}

export default EditEvent;
