import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import { SaveButton, Button } from 'react-admin';

function Modal({ open, onClose, onSave, title, children }) {
    return (
        <Dialog fullWidth aria-label={title} open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>{children}</DialogContent>
            <DialogActions>
                <Button label="ra.action.cancel" onClick={onClose}>
                    <IconCancel />
                </Button>
                <SaveButton handleSubmitWithRedirect={onSave} />
            </DialogActions>
        </Dialog>
    );
}

export default Modal;
