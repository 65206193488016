import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 20px 0;
    width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const TableWrapper = styled.div`
    padding: 10px 0;
`;

export const ButtonWrapper = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: center;
`;
