import React from 'react';
import { usePermissions } from 'react-admin';
import AdminDashboard from './admin';
import EditorDashboard from './editor';

function Dashboard() {
    const { permissions: { role } = { role: 'editor' } } = usePermissions();

    return role !== 'editor' ? <AdminDashboard /> : <EditorDashboard />;
}

export default Dashboard;
