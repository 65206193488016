import React from 'react';
import moment from 'moment-timezone';

function DateField({ record, source, showTime = false }) {
    let format = 'DD/MM/YYYY';
    if (showTime) {
        format += ' HH:mm';
    }

    return record && record[source] ? <>{moment(record[source]).tz('Europe/Athens').format(format)}</> : null;
}

export default DateField;
