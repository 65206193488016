import { List, Datagrid, TextField } from 'react-admin';

const PostersList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="title" label="common.title" />
                <TextField source="type" />
                <TextField source="order" label="common.order" />
            </Datagrid>
        </List>
    );
};

export default PostersList;
