import { Filter, List, Datagrid, ReferenceInput, SelectInput, TextField, useGetIdentity } from 'react-admin';
import ListAside from './ListAside';

const Filters = ({ ...rest }) => {
    return (
        <Filter {...rest}>
            <ReferenceInput source="referrerId" reference="users" filter={{ scope: 'operators' }}>
                <SelectInput optionText={(user) => (user ? `${user.firstName} ${user.lastName}` : '')} />
            </ReferenceInput>
        </Filter>
    );
};

const UersList = (props) => {
    const { identity } = useGetIdentity();

    return identity ? (
        <List
            aside={<ListAside />}
            {...props}
            filters={<Filters />}
            filterDefaultValues={{ referrerId: identity ? identity.id : null }}
        >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="title" label="common.title" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="email" />
                <TextField source="role" />
            </Datagrid>
        </List>
    ) : null;
};

export default UersList;
