import React, { useEffect, useState } from 'react';
import { useDataProvider, useGetIdentity, useTranslate } from 'react-admin';
import styled from 'styled-components';
import { Comment, Contacts, Payment, Group } from '@material-ui/icons';
import AuditLog from './AuditLog';
import Card from '../Card';
import PendingList from './PendingList';
import PendingCommentsList from './PendingCommentsList';

const CardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding-top: 10px;

    @media (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const styles = {
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

function AdminDashboard() {
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const [listingsTotalCount, setListingsTotalCount] = useState(0);
    const [subscriptionsTotalCount, setSubsciptionsTotalCount] = useState(0);
    const [usersTotalCount, setUsersTotalCount] = useState(0);
    const [commentsTotalCount, setCommentsTotalCount] = useState(0);
    const [pendingListings, setPendingListings] = useState([]);
    const [pendingEvents, setPendingEvents] = useState([]);
    const [pendingComments, setPendingComments] = useState([]);
    const t = useTranslate();

    useEffect(() => {
        if (identity) {
            Promise.all([
                dataProvider.getList('listings', {
                    pagination: { page: 1, perPage: 0 },
                    sort: {},
                    filter: {
                        scope: 'active',
                    },
                }),
                dataProvider.getList('subscriptions', {
                    pagination: { page: 1, perPage: 0 },
                    sort: {},
                    filter: { scope: 'active' },
                }),
                dataProvider.getList('users', {
                    pagination: { page: 1, perPage: 0 },
                    sort: {},
                    filter: {
                        role: 'editor',
                        status: 1,
                    },
                }),
                dataProvider.getList('comments', {
                    pagination: { page: 1, perPage: 0 },
                    sort: {},
                    filter: {},
                }),
                dataProvider.getList('listings', {
                    pagination: { page: 1, perPage: 50 },
                    sort: {},
                    filter: { isApproved: false },
                }),
                dataProvider.getList('events', {
                    pagination: { page: 1, perPage: 50 },
                    sort: {},
                    filter: { isApproved: false },
                }),
                dataProvider.getList('comments', {
                    pagination: { page: 1, perPage: 50 },
                    sort: {},
                    filter: { status: 0 },
                }),
            ]).then(
                ([
                    listingsResponse,
                    subscriptionsResponse,
                    usersResponse,
                    commentsResponse,
                    pendingListings,
                    pendingEvents,
                    pendingComments,
                ] = []) => {
                    setListingsTotalCount(listingsResponse?.total || 0);
                    setSubsciptionsTotalCount(subscriptionsResponse?.total || 0);
                    setUsersTotalCount(usersResponse?.total || 0);
                    setCommentsTotalCount(commentsResponse?.total || 0);
                    setPendingListings(pendingListings?.data || 0);
                    setPendingEvents(pendingEvents?.data || 0);
                    setPendingComments(pendingComments?.data || 0);
                }
            );
        }
    }, [identity, dataProvider]);

    return identity ? (
        <div>
            <CardsWrapper>
                <Card
                    to="/listings"
                    icon={<Contacts fontSize="large" />}
                    title={t('resources.listings.name', listingsTotalCount)}
                    subtitle={listingsTotalCount}
                />
                <Card
                    to="/subscriptions"
                    icon={<Payment fontSize="large" />}
                    title={t('resources.subscriptions.name', subscriptionsTotalCount)}
                    subtitle={subscriptionsTotalCount}
                />
                <Card
                    to="/users"
                    icon={<Group fontSize="large" />}
                    title={t('resources.users.name', usersTotalCount)}
                    subtitle={usersTotalCount}
                />
                <Card
                    to="/comments"
                    icon={<Comment fontSize="large" />}
                    title={t('resources.comments.name', commentsTotalCount)}
                    subtitle={commentsTotalCount}
                />
            </CardsWrapper>
            <div>
                {pendingListings.length > 0 && (
                    <div style={styles.singleCol}>
                        <PendingList
                            resource="listings"
                            title={t('dashboard.pendingListings')}
                            list={pendingListings}
                        />
                    </div>
                )}
                {pendingEvents.length > 0 && (
                    <div style={styles.singleCol}>
                        <PendingList resource="events" title={t('dashboard.pendingEvents')} list={pendingEvents} />
                    </div>
                )}
                {pendingComments.length > 0 && (
                    <div style={styles.singleCol}>
                        <PendingCommentsList list={pendingComments} />
                    </div>
                )}
            </div>
            {identity && (identity.id === 1 || identity.id === 2) && <AuditLog />}
        </div>
    ) : null;
}

export default AdminDashboard;
