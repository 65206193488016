const customReferenceParseFormat = {
    format: (value) => {
        if (Array.isArray(value)) {
            return value.map((item) => item.id);
        }
    },
    parse: (value) => {
        return value.map((item) => ({ id: item }));
    },
};

const truncate = (input, textLength) => (input.length > textLength ? `${input.substring(0, textLength)}...` : input);

export { customReferenceParseFormat, truncate };
