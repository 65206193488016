import { Edit } from 'react-admin';
import Form from './Form';

const EditArticleCategory = (props) => {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <Form {...props} />
        </Edit>
    );
};

export default EditArticleCategory;
