import { List, Datagrid, TextField, Filter, SearchInput, NumberField } from 'react-admin';

const Filters = ({ filterValues, ...rest }) => {
    return (
        <Filter filterValues={filterValues} {...rest}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};

function EventCategoriesList(props) {
    return (
        <List filters={<Filters />} sort={{ field: 'order', order: 'DESC' }} {...props}>
            <Datagrid optimized rowClick="edit">
                <NumberField source="order" label="common.order" />
                <TextField source="title" label="common.title" />
            </Datagrid>
        </List>
    );
}

export default EventCategoriesList;
