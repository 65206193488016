import List from './List';
import Create from './Create';
import Edit from './Edit';

const resource = (ability) => ({
    list: ability.can('read', 'Subscription') ? List : null,
    create: ability.can('create', 'Subscription') ? Create : null,
    edit: ability.can('update', 'Subscription') ? Edit : null,
});

export default resource;
