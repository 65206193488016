import { useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableFooter,
    TableContainer,
    Paper,
    Button,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import uniq from 'lodash/uniq';

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function ClaimedCoupons(props) {
    const { ids } = props;
    const [data, setData] = useState({ claimedCoupons: [], coupons: [], events: [] });
    const dataProvider = useDataProvider();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const t = useTranslate();
    const [loadClaimedCoupons, setLoadClaimedCoupons] = useState(false);
    const [loadedClaimedCoupons, setLoadedClaimedCoupons] = useState(false);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.claimedCoupons.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        async function fetchClaimedCoupons() {
            try {
                if (ids.length > 0 && loadClaimedCoupons && !loadedClaimedCoupons) {
                    console.log('fetching coupons');
                    const { data: claimedCoupons } = await dataProvider.getList('claimedcoupons', {
                        pagination: { page: 1, perPage: 10000 },
                        sort: { field: 'createdAt', order: 'DESC' },
                        filter: { 'coupon.couponevent.eventId': ids },
                    });
                    const couponIds = uniq(claimedCoupons.map((c) => c.couponId));
                    const eventIds = uniq(claimedCoupons.map((c) => c.coupon.couponevent.eventId));

                    let coupons = [];
                    let events = [];
                    if (couponIds.length > 0) {
                        const data = await dataProvider.getList('coupons', {
                            pagination: { page: 1, perPage: 10000 },
                            sort: { field: 'createdAt', order: 'DESC' },
                            filter: { id: couponIds },
                        });
                        coupons = data.data;
                    }
                    if (eventIds.length > 0) {
                        const data= await dataProvider.getList('events', {
                            pagination: { page: 1, perPage: 10000 },
                            sort: { field: 'createdAt', order: 'DESC' },
                            filter: { id: eventIds },
                        });
                        events = data.data;
                    }

                    setData({ claimedCoupons, coupons, events });
                    setLoadedClaimedCoupons(true);
                }
            } catch (error) {}
        }
        fetchClaimedCoupons();
    }, [ids, loadClaimedCoupons, loadedClaimedCoupons, dataProvider]);

    return loadClaimedCoupons && loadClaimedCoupons ? (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('resources.discountcodes.fields.code')}</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>{t('common.phone')}</TableCell>
                        <TableCell>{t('common.name')}</TableCell>
                        <TableCell>{t('resources.events.name', { smart_count: 1 })}</TableCell>
                        <TableCell>{t('resources.claimedcoupons.seat')}</TableCell>
                        <TableCell>Εισιτήριο</TableCell>
                        <TableCell>{t('common.status')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? data.claimedCoupons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : data.claimedCoupons
                    ).map((c) => {
                        const event = data.events.find((e) => e.id === c.coupon?.couponevent?.eventId);
                        const coupon = data.coupons.find((cp) => cp.id === c.couponId);
                        return (
                            <TableRow key={c.id}>
                                <TableCell>{c.hashCode}</TableCell>
                                <TableCell>{c.email}</TableCell>
                                <TableCell>{c.phone}</TableCell>
                                <TableCell>{c.name}</TableCell>
                                <TableCell>{event ? event.title : ''}</TableCell>
                                <TableCell>{c?.seat?.name}</TableCell>
                                <TableCell>{coupon ? coupon.title : ''}</TableCell>
                                <TableCell>{c.validatedAt ? 'Επαληθεύτηκε' : 'Δεν Επαληθεύτηκε'}</TableCell>
                            </TableRow>
                        );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={8} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={8}
                            count={data.claimedCoupons.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    ) : (
        <div>
            <Button onClick={() => setLoadClaimedCoupons(true)}>Φόρτωση κρατήσεων</Button>
        </div>
    );
}
export default ClaimedCoupons;
