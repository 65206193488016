import React, { useEffect, useState } from 'react';
import { useDataProvider, useGetIdentity, useTranslate } from 'react-admin';
import styled from 'styled-components';
import { ConfirmationNumber, Contacts, Description, Event } from '@material-ui/icons';
import Card from '../Card';

const CardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding-top: 10px;

    @media (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

function EditorDashboard() {
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const [listingsTotalCount, setListingsTotalCount] = useState(0);
    const [eventsTotalCount, setEventsTotalCount] = useState(0);
    const [articlesTotalCount, setArticlesTotalCount] = useState(0);
    const [couponsTotalCount, setCouponsTotalCount] = useState(0);
    const t = useTranslate();

    useEffect(() => {
        if (identity) {
            Promise.all([
                dataProvider.getList('listings', {
                    pagination: { page: 1, perPage: 0 },
                    sort: {},
                    filter: { clientId: identity.id },
                }),
                dataProvider.getList('events', {
                    pagination: { page: 1, perPage: 0 },
                    sort: {},
                    filter: { listing: { clientId: identity.id } },
                }),
                dataProvider.getList('articles', {
                    pagination: { page: 1, perPage: 0 },
                    sort: {},
                    filter: { authorId: identity.id },
                }),
                dataProvider.getList('coupons', {
                    pagination: { page: 1, perPage: 0 },
                    sort: {},
                    filter: { listing: { clientId: identity.id } },
                }),
            ]).then(([listingsResponse, eventsResponse, articlesResponse, couponsResponse]) => {
                setListingsTotalCount(listingsResponse.total);
                setEventsTotalCount(eventsResponse.total);
                setArticlesTotalCount(articlesResponse.total);
                setCouponsTotalCount(couponsResponse.total);
            });
        }
    }, [identity, dataProvider]);

    return identity ? (
        <div>
            <CardsWrapper>
                <Card
                    to="/listings"
                    icon={<Contacts fontSize="large" />}
                    title={t('resources.listings.name', listingsTotalCount)}
                    subtitle={listingsTotalCount}
                />
                <Card
                    to="/coupons"
                    icon={<ConfirmationNumber fontSize="large" />}
                    title={t('resources.coupons.name', couponsTotalCount)}
                    subtitle={couponsTotalCount}
                />
                <Card
                    to="/events"
                    icon={<Event fontSize="large" />}
                    title={t('resources.events.name', eventsTotalCount)}
                    subtitle={eventsTotalCount}
                />
                <Card
                    to="/articles"
                    icon={<Description fontSize="large" />}
                    title={t('resources.articles.name', articlesTotalCount)}
                    subtitle={articlesTotalCount}
                />
            </CardsWrapper>
        </div>
    ) : null;
}

export default EditorDashboard;
