import { List, Datagrid, BooleanField, TextField } from 'react-admin';

const PlansList = (props) => {
    return (
        <List {...props}>
            <Datagrid>
                <TextField source="title" />
                <TextField source="price" />
                <TextField source="listingsNumber" />
                <TextField source="imagesNumber" />
                <BooleanField source="hasMediaInContent" />
                <BooleanField source="hasVideo" />
                <BooleanField source="hasCoupons" />
                <BooleanField source="hasArticles" />
                <BooleanField source="hasPosters" />
            </Datagrid>
        </List>
    );
};

export default PlansList;
