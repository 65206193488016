import { List, Datagrid, TextField, Filter, SearchInput, ReferenceField } from 'react-admin';

const Filters = (props) => {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};

const AreaList = (props) => {
    return (
        <List filters={<Filters />} {...props}>
            <Datagrid optimized rowClick="edit">
                <TextField source="id" />
                <TextField source="name" label="common.name" />
                <ReferenceField source="cityId" reference="cities">
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export default AreaList;
