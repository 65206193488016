const raTreeGreekMessages = {
    'ra-tree': {
        drag_preview: 'Κόμβος #%{id} |||| Κόμβος #%{id} με %{smart_count} παιδιά',
        root_target: 'Αφήστε ένα στοιχείο εδώ για να μετατρέψετε σε κορυφαίο επίπεδο',
        item_moved: 'Το αντικείμενο μετακινήθηκε',
        new_node: 'Νέος Κόμβος',
        action: {
            add_root: 'Προσθήκη πατρικού',
            add_child: 'Προσθήκη παιδιού',
        },
    },
};

export default raTreeGreekMessages;
