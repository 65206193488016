import React, { useState, useCallback } from 'react';
import {
    ReferenceInput,
    SelectInput,
    required,
    TextInput,
    useInput,
    AutocompleteInput,
    usePermissions,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import QuickCreate from '../common/QuickCreate';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
});

const AreaReferenceInput = (props) => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    const { input } = useInput({ source: 'areaId' });
    const form = useForm();
    const handleChange = useCallback(() => {
        setVersion(version + 1);
    }, [version]);
    const { permissions: { role } = { role: 'editor' } } = usePermissions();

    React.useEffect(() => {}, [form, input.value]);

    return (
        <div className={classes.root}>
            <div>
                <ReferenceInput key={version} {...props}>
                    <AutocompleteInput
                        matchSuggestion={() => true}
                        optionText={(props) => {
                            if (props.city) {
                                return `${props.city.name} - ${props.name}`;
                            } else {
                                return props.name;
                            }
                        }}
                    />
                </ReferenceInput>
            </div>

            {role !== 'editor' && (
                <QuickCreate onChange={handleChange} source="areaId" resource="areas">
                    <TextInput source="name" validate={required()} fullWidth />
                    <ReferenceInput
                        source="cityId"
                        fullWidth
                        reference="cities"
                        validate={[required()]}
                        // defaultValue={input.value}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </QuickCreate>
            )}
        </div>
    );
};

export default AreaReferenceInput;
