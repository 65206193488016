import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

const PendingList = ({ resource, title, list = [] }) => {
    const classes = useStyles();
    const t = useTranslate();
    return (
        <Card className={classes.root}>
            <CardHeader title={title} />
            <List dense={true}>
                {list.map((record) => (
                    <ListItem key={record.id} button component={Link} to={`/${resource.toLowerCase()}/${record.id}`}>
                        <ListItemText
                            primary={record.title}
                            secondary={`${t('common.updatedAt')}: ${new Date(record.updatedAt).toLocaleString('en-GB')}`}
                        />
                        <ListItemSecondaryAction>
                            <span className={classes.cost}>
                                {record.status === 1 ? t('dashboard.active') : t('dashboard.notActive')}
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};

export default PendingList;
