import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import { Button, SaveButton, useCreate, useNotify, FormWithRedirect, useTranslate } from 'react-admin';
import inflection from 'inflection';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function QuickCreate({ onChange, resource, source, children, initialValues }) {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate(resource);
    const notify = useNotify();
    const form = useForm();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async (values) => {
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    if (source) {
                        form.change(source, data.id);
                    }
                    onChange();
                },
                onFailure: ({ message }) => {
                    notify(message, 'error');
                },
            }
        );
    };

    const translate = useTranslate();

    const resourceName = translate(`resources.${resource}.name`, {
        smart_count: 1,
        _: inflection.humanize(inflection.singularize(resource)),
    });
    const defaultTitle = translate('ra.page.create', {
        name: `${resourceName}`,
    });

    const formProps = {};
    if (initialValues) {
        formProps.initialValues = initialValues;
    }

    return (
        <>
            <Button onClick={handleClick} label="ra.action.create">
                <IconContentAdd />
            </Button>
            <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label={defaultTitle}>
                <DialogTitle>{defaultTitle}</DialogTitle>

                <FormWithRedirect
                    {...formProps}
                    resource={resource}
                    save={handleSubmit}
                    render={({ handleSubmitWithRedirect, pristine, saving }) => (
                        <>
                            <DialogContent>{children}</DialogContent>
                            <DialogActions>
                                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

QuickCreate.defaultProps = {
    onChange: () => {},
};

export default QuickCreate;
