import {
    AutocompleteArrayInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    TabbedForm,
    FormTab,
    TextInput,
    NumberInput,
    AutocompleteInput,
    SelectInput,
} from 'react-admin';
import config from '../config';
import { TinyMCEInput } from '../fields/tinymceinput';
import S3FileInput from '../fields/s3-input';
import { customReferenceParseFormat } from '../helpers/utils';

function Form(props) {
    return (
        <TabbedForm {...props}>
            <FormTab label="common.summary">
                <TextInput source="title" label="common.title" fullWidth />
                <ReferenceInput source="authorId" reference="users" allowEmpty>
                    <AutocompleteInput optionText={(user) => (user && user.id ? `${user.firstName} ${user.lastName}` : '')} />
                </ReferenceInput>
                <TextInput source="authorName" />
                <TextInput source="authorDescription" />
                <ReferenceInput source="categoryId" reference="categories">
                    <AutocompleteInput
                        optionText={(category) =>
                            category ? `${category.title} ${category.parent ? `(${category.parent.title})` : ''}` : ''
                        }
                    />
                </ReferenceInput>
                <ReferenceInput
                    source="articleCategoryId"
                    reference="articlecategories"
                >
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>
                <NumberInput source="order" label="common.order" />
                <SelectInput
                    source="status"
                    label="common.status"
                    choices={[
                        { id: 0, name: 'Not Active' },
                        { id: 1, name: 'Active' },
                    ]}
                />
                <TextInput
                    source="shortDescription"
                    multiline
                    fullWidth
                />
                <ReferenceArrayInput
                    source="tags"
                    reference="tags"
                    {...customReferenceParseFormat}
                >
                    <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="common.image">
                <S3FileInput
                    source="image"
                    label="common.image"
                    apiRoot={config.apiUrl}
                    uploadOptions={{
                        server: config.apiUrl,
                        signingUrl: '/s3/sign',
                    }}
                    validate={[required()]}
                />
            </FormTab>
            <FormTab label="resources.articles.fields.content">
                <TinyMCEInput source="content" label="resources.articles.fields.content" />
            </FormTab>
            <FormTab label="common.meta">
                <TextInput
                    source="metaDescription"
                    label="common.metaDescription"
                    multiline
                    fullWidth
                />
                <TextInput source="metaKeywords" label="common.metaKeywords" fullWidth />
            </FormTab>
        </TabbedForm>
    );
}

export default Form;
