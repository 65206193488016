import { useCallback, useState } from 'react';
import {
    AutocompleteInput,
    AutocompleteArrayInput,
    required,
    ReferenceInput,
    TextInput,
    TextField,
    NumberInput,
    SelectInput,
    TabbedForm,
    FormTab,
    ReferenceManyField,
    ReferenceArrayInput,
    Datagrid,
    EditButton,
    BooleanInput,
    usePermissions,
    useTranslate,
    Pagination,
    DateInput,
    // useGetIdentity,
} from 'react-admin';
import config from '../config';
import { GMapInput } from '../fields/gmap';
import S3FileInput from '../fields/s3-input';
import TinyMCEInput from '../fields/tinymceinput';
import QuickCreate from '../common/QuickCreate';
import { customReferenceParseFormat } from '../helpers/utils';
import DateField from '../fields/dateField';
import ClaimedCoupons from '../users/ClaimedCoupons';

export default function Form({formFor, ...props}) {
    const { permissions: { role } = { role: 'editor' } } = usePermissions();
    // const { identity } = useGetIdentity();
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);
    const translate = useTranslate();

    let referenceProps = {};
    if (formFor === 'edit') {
        referenceProps = { ...customReferenceParseFormat };
    }

    return (
        <TabbedForm {...props}>
            <FormTab label="Info">
                <TextInput source="title" label="common.title" />
                <TextInput source="shortDescription" />
                <TinyMCEInput source="description" />
                <TextInput source="phone" />
                <TextInput source="mobilePhone" />
                <TextInput source="url" />
                <TextInput source="youtubeUrl" />
                <TextInput source="facebookUrl" />
                <TextInput source="instagramUrl" />
                <TextInput source="address" />
                <TextInput source="hours" multiline fullWidth />
                {role !== 'editor' && <TextInput source="notes" label="common.notes" />}
                {role !== 'editor' && <BooleanInput source="isApproved" />}
                {role !== 'editor' && (
                    <SelectInput
                        source="status"
                        label="common.status"
                        choices={[
                            { id: 0, name: 'Not Active' },
                            { id: 1, name: 'Active' },
                        ]}
                    />
                )}
                {role !== 'editor' && (
                    <ReferenceArrayInput
                        source="categories"
                        reference="categories"
                        label={translate('resources.categories.name', { smart_count: 2 })}
                        {...referenceProps}
                    >
                        <AutocompleteArrayInput
                            optionText={(category) => (category ? `${category.title} (${category.parent.title})` : '')}
                        />
                    </ReferenceArrayInput>
                )}
                {role !== 'editor' && (
                    <ReferenceArrayInput
                        source="cities"
                        reference="cities"
                        label={translate('resources.cities.name', { smart_count: 2 })}
                        {...referenceProps}
                    >
                        <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                )}
                {role !== 'editor' && (
                    <ReferenceArrayInput
                        source="areas"
                        reference="areas"
                        label={translate('resources.areas.name', { smart_count: 2 })}
                        {...referenceProps}
                    >
                        <AutocompleteArrayInput
                            matchSuggestion={() => true}
                            optionText={(props) => {
                                // TODO: We might need to remove this handler.
                                if (props && props.city) {
                                    return `${props.city.name} - ${props.name}`;
                                } else {
                                    return props ? props.name : '-';
                                }
                            }}
                        />
                    </ReferenceArrayInput>
                )}
                {role !== 'editor' && (
                    <ReferenceInput source="clientId" label="common.client" reference="users" validate={[required()]}>
                        <AutocompleteInput optionText={(user) => (user ? `${user.firstName} ${user.lastName}` : '')} />
                    </ReferenceInput>
                )}
                {role !== 'editor' && <TextInput source="everypaySellerId" label="Everypay Seller Id" />}
                {role !== 'editor' && <TextInput source="commission" />}
                {role !== 'editor' && <TextInput source="clientServiceFee" />}
            </FormTab>
            <FormTab label="common.images">
                <S3FileInput
                    apiRoot={config.apiUrl}
                    uploadOptions={{
                        server: config.apiUrl,
                        signingUrl: '/s3/sign',
                    }}
                    label="Logo"
                    source="logo"
                    validate={[required()]}
                />
                <S3FileInput
                    apiRoot={config.apiUrl}
                    uploadOptions={{
                        server: config.apiUrl,
                        signingUrl: '/s3/sign',
                    }}
                    label="Images"
                    multiple
                    source="images"
                />
            </FormTab>
            {role !== 'editor' && (
                <FormTab label="Meta">
                    {role !== 'editor' && <TextInput fullWidth source="metaDescription" />}
                    {role !== 'editor' && <TextInput fullWidth source="metaKeywords" />}
                </FormTab>
            )}
            {role !== 'editor' && (
                <FormTab label="common.map">
                    <GMapInput source="coordinates" />
                </FormTab>
            )}
            {role !== 'editor' && formFor === 'edit' && (
                <FormTab label={translate('resources.coupons.name', { smart_count: 2 })}>
                    <QuickCreate
                        onChange={handleChange}
                        resource="coupons"
                        initialValues={{ listingId: props.record.id }}
                    >
                        <TextInput source="title" validate={required()} fullWidth />
                        <TextInput source="description" validate={required()} fullWidth multiline />
                        <DateInput source="expirationDate" locales="fr-FR" />
                        <NumberInput source="count" validate={required()} fullWidth />
                        <NumberInput source="price" fullWidth />
                        {/* <ReferenceInput
                            filter={{ id: props ? props.id : undefined }}
                            source="listingId"
                            reference="listings"
                            validate={[required()]}
                        >
                            <SelectInput optionText="title" />
                        </ReferenceInput> */}
                    </QuickCreate>
                    <ReferenceManyField
                        reference="coupons"
                        target="listingId"
                        addLabel={false}
                        pagination={<Pagination />}
                        fullWidth
                        key={version}
                        sort={{ field: 'createdAt', order: 'DESC' }}
                    >
                        <Datagrid>
                            <TextField source="title" />
                            <TextField source="description" />
                            <DateField source="expirationDate" />
                            <TextField source="count" />
                            <TextField source="price" />
                            <TextField source="order" />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {formFor === 'edit' && (
                <FormTab label="Κρατήσεις">
                    <ReferenceManyField
                        reference="events"
                        target="listingId"
                        addLabel={false}
                        sort={{ field: 'createdAt', order: 'DESC' }}
                        fullWidth
                    >
                        <ClaimedCoupons />
                    </ReferenceManyField>
                </FormTab>
            )}
        </TabbedForm>
    );
}
