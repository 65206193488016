import React from 'react';
import {
    AutocompleteInput,
    DateInput,
    FormTab,
    TabbedForm,
    TextInput,
    required,
    ReferenceInput,
    SelectInput,
    PasswordInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    EditButton,
    Pagination,
    ChipField,
    usePermissions,
    useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';
import S3FileInput from '../fields/s3-input';
import config from '../config';
import AreaReferenceInput from './AreaReferenceInput';
import DateField from '../fields/dateField';
import KYCStatus from './KYCStatus';
import CreateSeller from './createSeller';
import ClaimedCoupons from './ClaimedCoupons';

const SanitizedBox = ({ fullWidth, basePath, ...props }) => <Box {...props} />;

const Form = ({ formFor, ...props }) => {
    const { record } = props;
    const { permissions: { role } = { role: 'editor' } } = usePermissions();
    const t = useTranslate();
    const roleChoices = [
        { id: 'viewer', name: 'Viewer' },
        { id: 'editor', name: 'Editor' },
        { id: 'author', name: 'Author' },
        { id: 'operator', name: 'Operator' },
        role === 'admin' && { id: 'admin', name: 'Admin' },
    ].filter(Boolean);

    return (
        <TabbedForm {...props}>
            <FormTab label="common.general">
                <SanitizedBox
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    justifyContent="space-between"
                    fullWidth
                >
                    <TextInput disabled source="id" />
                    <TextInput source="title" label="common.title" />
                    <TextInput source="firstName" label="resources.users.fields.firstName" validate={required()} />
                    <TextInput source="lastName" label="resources.users.fields.lastName" validate={required()} />
                    <TextInput source="contactName" label="resources.users.fields.contactName" fullWidth multiline />
                    <TextInput source="description" label="common.description" fullWidth multiline />
                    <TextInput source="phone" label="common.phone" fullWidth multiline />
                    <TextInput source="mobilePhone" label="resources.users.fields.mobilePhone" fullWidth multiline />
                    <TextInput source="notes" label="common.notes" fullWidth multiline />
                    {role !== 'editor' && <TextInput source="everypaySellerId" label="Everypay Seller Id" />}
                    {role !== 'editor' && <TextInput label="resources.users.fields.commission" source="commission" />}
                    {role !== 'editor' && (
                        <TextInput label="resources.users.fields.clientServiceFee" source="clientServiceFee" />
                    )}
                </SanitizedBox>
                <TextInput source="email" validate={required()} />
                <PasswordInput source="password" />
            </FormTab>
            <FormTab label="resources.users.fields.miscellaneous">
                <DateInput disabled source="createdAt" label="common.createdAt" options={{ locale: 'el' }} />
                {/* <DateInput disabled source="updatedAt" options={{ locale: 'el' }} /> */}
                <SelectInput
                    source="status"
                    label="common.status"
                    choices={[
                        { id: 0, name: 'common.notActive' },
                        { id: 1, name: 'common.active' },
                    ]}
                />
                <SelectInput source="role" choices={roleChoices} />
                <ReferenceInput source="referrerId" reference="users" filter={{ scope: 'operators' }}>
                    <AutocompleteInput optionText={(user) => (user ? `${user.firstName} ${user.lastName}` : '')} />
                </ReferenceInput>
                <ReferenceInput source="cityId" reference="cities">
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <AreaReferenceInput
                    source="areaId"
                    reference="areas"
                    allowEmpty
                    validate={required()}
                    perPage={10000}
                />
            </FormTab>
            {role !== 'editor' && (
                <FormTab label="Everypay">
                    {record.everypaySellerId && (
                        <TextInput
                            id="everypay-everypaySellerId"
                            source="everypaySellerId"
                            label="Everypay Seller Id"
                        />
                    )}
                    {record.everypaySellerId && <TextInput id="everypay-commission" source="commission" />}
                    {record.everypaySellerId && <TextInput source="clientServiceFee" />}
                    {record.everypaySellerId && record.everypayKYC ? <KYCStatus /> : <CreateSeller />}
                </FormTab>
            )}
            <FormTab label="Marketplace">
                <TextInput source="address" fullWidth />
                <TextInput source="tin" />
                <TextInput source="taxOffice" />
            </FormTab>
            <FormTab label="common.image">
                <S3FileInput
                    source="image"
                    label="common.image"
                    apiRoot={config.apiUrl}
                    uploadOptions={{
                        server: config.apiUrl,
                        signingUrl: '/s3/sign',
                    }}
                />
            </FormTab>
            {formFor === 'edit' && (
                <FormTab label={t('resources.listings.name', 2)}>
                    <ReferenceManyField
                        reference="listings"
                        target="clientId"
                        addLabel={false}
                        pagination={<Pagination />}
                        fullWidth
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="title" />
                            <TextField source="phone" />
                            <TextField source="mobilePhone" />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {formFor === 'edit' && (
                <FormTab label={t('resources.coupons.name', 2)}>
                    <ReferenceManyField
                        reference="coupons"
                        target="listing.clientId"
                        addLabel={false}
                        pagination={<Pagination />}
                        fullWidth
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="description" />
                            <TextField source="count" />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {formFor === 'edit' && (
                <FormTab label={t('resources.subscriptions.name', 2)}>
                    <ReferenceManyField
                        reference="subscriptions"
                        target="clientId"
                        addLabel={false}
                        pagination={<Pagination />}
                        fullWidth
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="title" />
                            <ChipField source="paymentMethod" />
                            <TextField source="price" />
                            <TextField source="notes" />
                            <DateField source="expiresAt" />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {formFor === 'edit' && (
                <FormTab label={t('resources.articles.name', 2)}>
                    <ReferenceManyField
                        reference="articles"
                        target="authorId"
                        addLabel={false}
                        pagination={<Pagination />}
                        fullWidth
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="title" />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {formFor === 'edit' && (
                <FormTab label={t('resources.events.name', 2)}>
                    <ReferenceManyField
                        reference="events"
                        target="listing.clientId"
                        addLabel={false}
                        pagination={<Pagination />}
                        sort={{ field: 'createdAt', order: 'DESC' }}
                        fullWidth
                    >
                        <Datagrid>
                            <TextField source="title" />
                            <DateField source="startsAt" showTime />
                            <DateField source="endsAt" showTime />
                            <DateField source="createdAt" showTime />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {formFor === 'edit' && (
                <FormTab label="Κρατήσεις">
                    <ReferenceManyField
                        reference="events"
                        target="listing.clientId"
                        addLabel={false}
                        sort={{ field: 'createdAt', order: 'DESC' }}
                        fullWidth
                    >
                        <ClaimedCoupons />
                    </ReferenceManyField>
                </FormTab>
            )}
        </TabbedForm>
    );
};

export default Form;
