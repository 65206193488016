import { Admin, Resource, mergeTranslations } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { addTreeMethodsBasedOnParentAndPosition, raTreeLanguageEnglish, reducer as tree } from '@kidsproject/ra-tree';
import { addSearchMethod, raSearchLanguageEnglish } from '@kidsproject/ra-search';
import { addEventsForMutations, EventList, raAuditLogLanguageEnglish } from '@kidsproject/ra-audit-log';
import { Route } from 'react-router-dom';
import jsonServerProvider, { fetchClient } from './jsonServerProvider';
import authProvider from './JwtAuthProvider';
import config from './config';
import areas from './areas';
import articles from './articles';
import articleCategories from './articleCategories';
import coupons from './coupons';
import claimedCoupons from './claimedcoupons';
import plans from './plans';
import posters from './posters';
import categories from './categories';
import cities from './cities';
import comments from './comments';
import educationalArticles from './educationalArticles';
import events from './events';
import invoiceSeries from './invoiceSeries';
import eventCategories from './eventCategories';
import listings from './listings';
import subscriptions from './subscriptions';
import tags from './tags';
import users from './users';
import receipts from './receipts';
import Layout from './layout';
import Dashboard from './dashboard';
import Login from './layout/login.react';
import { truncate } from './helpers/utils';
import englishMessages from './i18n/en';
import greekMessages from './i18n/el';
import raTreeLanguageGreek from './i18n/raTreeGreek';
import raSearchLanguageGreek from './i18n/raSearchGreek';
import raAuditLogLanguageGreek from './i18n/raAuditLogGreek';
import AwsUpload from './awsUpload';
import ValidateReservation from './validateReservation';

const dataProvider = addEventsForMutations(jsonServerProvider(config.apiUrl), authProvider, { name: 'auditlogevents' });

let dataProviderWithTree = addTreeMethodsBasedOnParentAndPosition(dataProvider, 'parentId', 'order', true);

dataProviderWithTree = {
    ...dataProviderWithTree,
    moveAsNthSiblingOf: async (resource, { source, destination, position }) => {
        await fetchClient(`${config.apiUrl}/${resource}/moveAsNthSiblingOf`, {
            method: 'POST',
            body: JSON.stringify({ source, destination, position }),
        });

        return { data: {} };
    },
};

const dataProviderWithSearch = addSearchMethod(dataProviderWithTree, {
    articles: {
        label: 'title',
        description: (record) => `${truncate((record.content || '').replace(/<[^>]*>?/gm, ''), 300)}`,
    },
    categories: {
        label: 'title',
        description: 'description',
    },
    listings: {
        label: 'title',
        description: (record) => `${truncate((record.description || '').replace(/<[^>]*>?/gm, ''), 300)}`,
    },
    subscriptions: {
        label: 'title',
        description: 'notes',
    },
});

const i18nProvider = polyglotI18nProvider((locale) => {
    if (locale === 'en') {
        return mergeTranslations(
            englishMessages,
            raTreeLanguageEnglish,
            raSearchLanguageEnglish,
            raAuditLogLanguageEnglish
        );
    }
    return mergeTranslations(greekMessages, raTreeLanguageGreek, raSearchLanguageGreek, raAuditLogLanguageGreek);
}, 'el');

const App = () => (
    <Admin
        dataProvider={dataProviderWithSearch}
        i18nProvider={i18nProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        disableTelemetry
        customReducers={{ tree }}
        customRoutes={[
            <Route exact path="/aws-upload" component={AwsUpload} />,
            <Route exact path="/validate-reservation" component={ValidateReservation} />,
        ]}
    >
        {({ ability }) => {
            return [
                <Resource name="areas" {...areas(ability)} />,
                <Resource name="articles" {...articles(ability)} />,
                <Resource
                    name="articlecategories"
                    options={{ label: 'Article Categories' }}
                    {...articleCategories(ability)}
                />,
                <Resource name="auditlogevents" list={EventList} />,
                <Resource name="categories" {...categories(ability)} />,
                <Resource name="cities" {...cities(ability)} />,
                <Resource name="claimedcoupons" {...claimedCoupons(ability)} />,
                <Resource name="comments" {...comments(ability)} />,
                <Resource name="coupons" {...coupons(ability)} />,
                <Resource name="discountcodes" />,
                <Resource name="educationalarticles" {...educationalArticles(ability)} />,
                <Resource name="educationalcategories" {...categories(ability)} />,
                <Resource name="educationaltags" {...tags(ability)} />,
                <Resource name="events" {...events(ability)} />,
                <Resource name="invoiceseries" {...invoiceSeries(ability)} />,
                <Resource
                    name="eventcategories"
                    options={{ label: 'Event Categories' }}
                    {...eventCategories(ability)}
                />,
                <Resource name="listings" {...listings(ability)} />,
                <Resource name="plans" {...plans(ability)} />,
                <Resource name="posters" {...posters(ability)} />,
                <Resource name="receipts" {...receipts(ability)} />,
                <Resource name="subscriptions" {...subscriptions(ability)} />,
                <Resource name="seats" />,
                <Resource name="tags" {...tags(ability)} />,
                <Resource name="theaters" />,
                <Resource name="users" {...users(ability)} />,
                <Resource name="userconsents" />,
            ];
        }}
    </Admin>
);

export default App;
